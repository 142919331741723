div.react-stars-component {
    display: flex;
    line-height: 1;
    user-select: none !important;
    div.stars-item {
        position: relative;
        width: fit-content;
        line-height: inherit;
        user-select: none !important;
        span.stars-item-over {
            position: absolute;
            top: 0;
            width: 50%;
            height: 100%;
            overflow: hidden;
            line-height: inherit;
            transition: color 0.3s;
            user-select: none !important;
            &.animation {
                transition: color 0.3s;
            }
            &.start {
                left: 0;
                right: auto;
                width: 52%;
                transform: none;
            }
            &.end {
                right: 0;
                left: auto;
                transform: scaleX(-1);
            }
        }
    }
    &[dir="rtl"] {
        div.stars-item {
            transform: scaleX(-1);
            user-select: none !important;
            span.stars-item-over {
                &.start {
                    transform: scaleX(-1);
                }
                &.end {
                    transform: none;
                }
            }
        }
    }
}
