section#course-main-card-left {
    width: 330px;
    position: relative;
    .card-icon {
        color: var(--main_color);
    }
    div {
        .course-main-page-card {
            position: fixed;
            top: 85px;
            left: 105px;
            @media (max-width: 767.98px){
            	position:static
            }
        }
        div.courses-card-box {
            div.image-hand {
                height: 185px;
            }
            div.courses-card {
                div.card-content {
                    max-height: none;
                    div.bay-card-middle {
                        @media (max-width: 767.98px){
                            margin-top: 4px;
                        }
                            div.bay-card-middle-top {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                font-weight: 600;
                                line-height: 1;
                                div.price-discount {
                                    display: block;
                                    line-height: 1;
                                    font-weight: 700;
                                    font-size: 45px;                      
                                }
                                div.price {
                                    font-weight: 700;
                                    font-size: 40px; 
                                    &::before {
                                        content: "";
                                        width: 0;
                                        height: 0;
                                        overflow: hidden;               
                                    }      
                                }
                                div.price-discount:not(:empty)+div.price {
                                    position: relative;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        height: 2px;
                                        width: 100%;
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        background-color: #777;
                                        border-radius: 1px;
                                    }
                                    font-weight: 400;
                                    line-height: 1;
                                    font-size: 18px;
                                    color: var(--main_title_color);
                                }
                            }
                            div.card-middle-bottom {
                                font-size: 16px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 0 4px;
                                margin-top: 5px;

                                div.card-bottom-top-right, div.card-bottom-top-left {
                                    display: flex;
                                    align-items: center;
                                    gap: 3px;
                                    .card-icon {
                                    font-size: 21px;

                                    }
                                    span.cart-discount, span.card-videos {
                                    font-size: 16px;
                                    font-weight: 700;

                                    }

                                }
                            }
                    }
                    div.card-bottom {
                            font-size: 21px;
                            div.card-bottom-top {
                                div.card-bottom-top-right {
                                    span.cart-numbers {
                                        font-size: 16px;
                                    }
                                }
                                div.card-bottom-top-left {
                                    span.card-hours {
                                        font-size: 16px;
                                    }
                                } 
                            }
                            div.card-main-category-bottom {
                                font-size: 16px;
                                font-weight: 700;
                                font-size: 16px;
                                display: flex;
                                align-items: center;
                                gap: 3px;
                                margin-top: 5px;
                                .card-icon {
                                    font-size: 21px;
                                }
                            }
                    }
                    div.btns-main-card {
                        margin-top: 15px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start; //
                        gap: 5px;
                        a.btn-pay-course, button.btn-pay-course {
                            width: 100%; //
                            display: block;
                            text-align: center;
                            font-size: 20px;
                            font-weight: 800;
                            background-image: var(--logo_gradient);
                        }
                        a.btn-wish-list, button.btn-wish-list {
                            pointer-events: none;
                        }
                    }
                }
            }
            div.card-category {
                display: none;
            }
        }
    }
}




