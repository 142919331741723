div.larg-courses-card {
    transition: transform 0.3s;
    &:nth-child(n + 4) {
        @media (max-width: 767.98px) {
            display: none;
        }
    }
    &:hover {
        transform: scale(1.05);
    }
}
div.courses-card-box {
    div.courses-card {
        width: 330px;
        border-radius: 8px;
        flex-shrink: 0;
        position: relative;
        div.image-hand {
            height: 180px;
            img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }
        }
        div.card-content {
            position: relative;
            top: -22px;
            background-color: var(--cards_bg_color);
            width: 90%;
            overflow: hidden;
            margin: auto;
            box-shadow: var(--box_shadow_one);
            border-radius: 8px;
            padding: 10px;
            div.card-middle {
                margin-top: 5px;
                padding: 0 4px;
                div.card-middle-top {
                    margin-top: 7px;
                    h3 {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 1;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    p {
                        margin-top: 2px;
                        font-size: 12px;
                    }
                }
            }
            div.card-bottom {
                margin-top: 7px;
                padding: 0 4px;
                div.card-bottom-top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    div.card-bottom-top-right {
                        display: flex;
                        align-items: center;
                        gap: 3px;
                        .card-icon {
                        }
                        span.cart-numbers {
                            font-size: 12px;
                            font-weight: 700;
                        }
                    }
                    div.card-bottom-top-left {
                        display: flex;
                        align-items: center;
                        gap: 3px;
                        span.card-hours {
                            font-size: 12px;
                            font-weight: 700;
                        }
                    }
                }
                div.card-bottom-bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row-reverse;
                    div.card-bottom-bottom-right {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        flex-direction: row-reverse;
                        div.rating-stars-icons {
                            direction: ltr;
                            line-height: 1;
                            span {
                                line-height: 1;
                            }
                        }
                        div.rating-number {
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }
                    div.card-bottom-bottom-left {
                        display: flex;
                        align-items: flex-end;
                        gap: 5px;
                        font-weight: 600;
                        line-height: 1;
                        div.price-discount {
                            display: block;
                            color: var(--main_title_color);
                            line-height: 1;
                            font-weight: 700;
                        }
                        div.price {
                            font-weight: 700;
                        }
                        div.price-discount:not(:empty) + div.price {
                            position: relative;
                            &::before {
                                content: "";
                                position: absolute;
                                height: 2px;
                                width: 100%;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                background-color: #777;
                                border-radius: 1px;
                            }
                            font-weight: 400;
                            line-height: 1;
                        }
                    }
                    .rating-mycourse {
                        font-weight: 600;
                        font-size: 14px;
                    }
                }
                div.btns-main-card {
                    display: flex;
                    justify-content: space-between;
                    gap: 5px;
                    button,
                    a {
                        display: block;
                        width: 50%;
                        text-align: center;
                        font-size: 12px;
                    }
                }
            }
        }
        div.card-category {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 8px;
            padding: 5px 10px;
            font-size: 14px;
            font-weight: 700;
            background-color: var(--level_bg_advanced);
            color: var(--level_color);
            min-width: 83%;
            text-align: center;
            line-height: 1.3;
            max-width: 254px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.6;
            max-height: calc(1.6 * 2);
        }
    }
}
