@forward "./sections/heroSection/HeroSection";
@forward "./sections/recentCoursesSection/RecentCoursesSection";
@forward "./sections/quoteSection/QuoteSection";
@forward "./sections/popularCoursesSection/PopularCoursesSection";
@forward "./sections/statisticsSection/StatisticsSection";
@forward "./sections/opinionsSection/OpinionsSection";
@forward "./sections/categoriesSection/CategoriesSection";

main#home-page {
    position: relative;
    z-index: 1;
    @media (max-width: 767.98px) {
        margin-top: 60px;
    }
}
