@forward "./units/CategoriesSideItem";
@use "./../../../../../helpers/mixin" as M;

div.aside-wrapper {
    .icon-expand {
        &.icon-open-sidebar {
            display: none;
            @media (max-width: 767.98px) {
                display: flex;
                position: fixed;
                top: 31px;
                right: 10px;
                z-index: 1200;
                transform: translate(50%, -50%);
                justify-content: center;
                align-items: center;
                width: 58px;
                height: 58px;
                padding: 13px;
                border-radius: 50%;
                transition: 0.3s;
                color: var(--main_color);
                cursor: pointer;
            }
            &.active {
                transform: translate(50%, -50%) rotate(180deg);
            }
        }
    }

    aside#side-bar-section.courses-right {
        width: 244px;
        height: fit-content;
        min-height: 714px;
        margin-bottom: 30px;
        @include M.coursesSection;
        position: relative;
        background-color: var(--cards_bg_color);
        @media (max-width: 767.98px) {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(156%);
            z-index: 1000;
            width: 100%;
            height: 100%;
            background-color: var(--sidebar_mobile_bg_color);
            transition: transform 0.3s;
            margin-top: 25px;
        }
        &.active {
            @media (max-width: 767.98px) {
                transform: translateX(0);
            }
        }
        .category-sidebar-title {
            display: block;
            margin: 8px auto 0;
            font-size: 20px;
            text-align: center;
        }
        div.category-sidebar-items {
            margin-top: 30px;
        }
    }
}
