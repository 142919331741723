section#quotes-section {
    margin: 10px auto 0;
    padding-bottom: 45px;
    h2.quotes-header {
        opacity: 100%;
        color: transparent;
        background-image: none;
        background-color: transparent;
        text-align: center;
        user-select: none !important;
        span {
            opacity: 100%;
            color: transparent;
            background-image: none;
            background-color: transparent;
            text-align: center;
            user-select: none !important;
        }
        &::before {
            background-image: none;
            user-select: none !important;
        }
    }
    div.container {
        display: flex;
        justify-content: center;
        gap: 50px;
        max-width: 800px;
        @media (max-width: 767.98px) {
            gap: 20px;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        div.quotes-left {
            div.quotes-image-hand {
                width: 150px;
                height: 150px;
                position: relative;
                img.quotes-image {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
                div.logo-image-hand {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    width: 150px;
                    height: 150px;
                    cursor: pointer;
                    img.logoLight {
                        width: 100%;
                        height: 100%;
                        display: block;
                        @at-root html.dark
                                section#quotes-section
                                div.container
                                div.quotes-left
                                div.logo-image-hand
                                img.logoLight {
                            display: none;
                        }
                    }
                    img.logoDark {
                        width: 100%;
                        height: 100%;
                        display: none;
                        @at-root html.dark
                                section#quotes-section
                                div.container
                                div.quotes-left
                                div.logo-image-hand
                                img.logoDark {
                            display: block;
                        }
                    }
                }
                .quotes-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: var(--quotes_icon_color);
                }
            }
        }
        blockquote.quotes-right {
            display: flex;
            align-items: center;
            border-radius: 8px;
            margin: 0;
            p.quotes-paragraph {
                padding: 15px;
                border-radius: 5px;
                font-size: 22px;
                font-weight: 800;
                &::before {
                    content: "\201D";
                }
                &::after {
                    content: "\201C";
                }
            }
            footer.quotes-who {
                text-indent: 16px;
                cite {
                    font-weight: 700;
                }
            }
        }
    }
}
