tr.video-list-row.table-body-row.courses-table-row {
    td.video-list-row.cell-row-3.cell-row-course {
        max-width: 135px;
        width: auto;
        @media (max-width: 767.98px) {
            max-width: 150px;
        }
        div.video-list-row.td-content {
            max-width: 350px;
            width: auto;
            @media (max-width: 767.98px) {
                max-width: 150px;
            }
            span.video-list-row.ellipsis {
                max-width: 236px;
                width: auto;
                @media (max-width: 767.98px) {
                    max-width: 150px;
                    width: auto;
                }
            }
        }
    }
    td.video-list-row.cell-row-pdf.cell-row-course {
        div.video-list-row.td-content {
            span.video-list-row.ellipsis {
                .pdf-name.ellipsis {
                    max-width: 50px;
                }
            }
        }
    }
    td.video-list-row.cell-row-4.cell-row-course {
        div.video-list-row.td-content {
            span.video-list-row.ellipsis {
                text-align: center;
                margin: auto;
            }
        }
    }
    td.video-list-row.cell-row-5.cell-row-course {
        div.video-list-row.td-content {
            width: 100%;
            span.video-list-row.ellipsis {
                width: 70px;
                text-align: center;
                margin: auto;
            }
        }
    }
    td.video-list-row.cell-row-6.cell-row-course {
        text-align: center;
        div.video-list-row.td-content {
            width: 100%;
            span.video-list-row.ellipsis {
                width: 70px;
                text-align: center;
                margin: auto;
            }
        }
    }
    td.video-list-row.cell-row-10.cell-row-course {
        div.video-list-row.td-content {
            .solid-common-btn.edit-btn-row {
                padding: 5px 13px !important;
            }
        }
    }
}
