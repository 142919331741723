@forward "./addCourse/AddCourse";
@forward "./editCourse/EditCourse";
@forward "./editChapter/EditChapter";

section#admin-courses-sections,
div.admin-courses-sections,
.admin-courses-sections {
    width: 100%;
    @media (max-width: 767.98px) {
        margin-top: 80px;
    }
}
div.course-controls-wrapper {
    display: flex;
    flex-direction: column;
    @media (max-width: 767.98px) {
        margin-top: 10px;
    }
    .add-course-btn {
        width: fit-content;
        align-self: flex-end;
        display: flex;
        align-items: center;
        gap: 15px;
        height: 35px;
        @media (max-width: 767.98px) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
div.courses-list-box {
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    position: relative;
    background-color: var(--cards_bg_color);
    margin-top: 10px;
    div.courses-list-top {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        @media (max-width: 767.98px) {
            flex-direction: column;
            align-items: center;
            gap: 10px;
        }
        div.courses-list-top-right {
            display: flex;
            align-items: center;
            h3.courses-table-header {
                font-size: 20px;
                font-weight: 800;
            }
        }
        .search {
            margin: 0;
            @media (max-width: 767.98px) {
                position: relative;
                bottom: auto;
                right: auto;
                z-index: auto;
                width: auto;
                display: block;
            }
        }
        div.courses-list-top-left {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
    div.courses-list-bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }
}