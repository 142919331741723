.box-arrow-scroll {
    width: fit-content;
    margin: auto;
    span {
        width: 20px;
        height: 20px;
        display: block;
        border-right: 2px solid var(--main_title_color);
        border-bottom: 2px solid var(--main_title_color);
        transform: rotate(45deg);
        margin: -10px;
        animation: animate 2s infinite;
        &:nth-child(2) {
            animation-delay: -0.2s;
        }
        &:nth-child(3) {
            animation-delay: -0.4s;
        }
    }
}
