.form-error-msg {
    color: red;
    position: absolute;
    top: calc(100%);
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    @at-root #login-section .form-error-msg {
        max-width: 70%;
        @media (max-width: 767.98px) {
            max-width: 56%;
        }
    }
}
