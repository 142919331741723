section#course-landing {
    text-align: center;
    margin-top: 20px;
}
div.course-landing-top {
    h1.course-landing-title {
        line-height: 1.2;
        font-size: 40px;
        font-weight: 900;
        position: relative;
        color: var(--main_color);
        @media (max-width: 991.98px) {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
        }
    }

    p.course-landing-description {
        font-weight: 500;
        font-size: 16px;
        margin-top: 15px;
        max-height: 240px;
        overflow-y: auto;
        @media (max-width: 991.98px) {
            font-size: 15px;
            margin-top: 7px;
            text-align: center;
        }
    }
}
div.course-landing-bottom {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 14px auto 0;
    gap: 5px;
    font-weight: 700;
    width: fit-content;
    text-align: center;
    @media (max-width: 767.98px) {
        flex-direction: column;
        margin-top: 5px;
        margin: auto;
        text-align: center;
        align-items: center;
        display: flex;
    }
    .opinions-landing-rate {
        font-size: 30px;
        @media (max-width: 767.98px) {
            font-size: 20px;
            margin-top: 7px;
        }
    }
    span.rating-average,
    span.students-number {
        font-size: 18px;
        @media (max-width: 991.98px) {
            font-size: 11px;
        }
    }
    div.rating-info-landing {
        display: flex;
        gap: 2px;
        @media (max-width: 767.98px) {
            gap: 7px;
        }
        span.rating-average {
        }
        span.students-number {
        }
    }
}

div.course-landing-desktop {
    display: flex;
    flex-direction: column;
    @media (max-width: 767.98px) {
        display: none;
    }
}

div.course-landing-mobile {
    display: none;
    @media (max-width: 767.98px) {
        display: flex;
        flex-direction: column;
    }
}
