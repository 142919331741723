@use "./units/ContentUnit";

section.courses-content {
    margin-top: 20px;
    div.courses-content-top {
        margin-bottom: 20px;
        .course-content-section-title {
            margin-bottom: 30px;
            @media (max-width: 991.98px) {
                margin: auto;
            }
        }
    }
    div.courses-content-bottom {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}
