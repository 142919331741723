section#summary-middle {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    @media (max-width: 991.98px) {
        flex-direction: column;
    }
    div.right {
        flex-shrink: 0;
        width: 33%;
        @media (max-width: 991.98px) {
            width: 100%;
        }
    }
    div.left {
        flex-grow: 1;
        div.selled-courses {
            @media (max-width: 767.98px) {
                width: 100%;
            }
            .title.ellipsis {
                max-width: 135px;
            }
            .course-id.ellipsis {
                max-width: 135px;
            }
            div.selled-courses-box {
                margin-top: 15px;
            }
        }
    }
}
div.selled-courses-left {
    span.nums-selled-courses {
        display: flex;
        justify-content: center;
        border: 1px solid #ccc;
        border-radius: 8px;
        width: 100px;
        margin: 0 15px;
        height: fit-content;
        line-height: 1.3;
        overflow: hidden;
    }
}
div.latest-profit {
    @media (max-width: 767.98px) {
        width: 100%;
    }
    div.profit-item {
        margin-top: 15px;
        background-color: var(--cards_bg_color);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 7px;
        padding: 15px;
        box-shadow: 0px 10px 15px -1px rgba(0, 0, 0, 0.1),
            0px 4px 6px 2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        .profet-icon {
            color: var(--main_color);
        }
        div.month-box {
            display: flex;
            flex-direction: column;
            gap: 7px;
            align-items: center;
            p.total-profit-this-month {
                font-weight: 500;
            }
            div.profit-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
                span.price {
                    font-size: 38px;
                    line-height: 1;
                    font-weight: 500;
                    color: var(--main_color);
                }
                span.and-symbol {
                    font-size: 38px;
                    line-height: 1;
                }
            }
        }
        div.percentage-profet-box {
            display: flex;
            justify-content: center;
            gap: 15px;
            text-align: center;
            width: 100%;
            div.percentage-profet-item {
                width: fit-content;
                span {
                    font-weight: 500;
                    line-height: 1;
                }
                div.percentage-box {
                    display: flex;
                    justify-content: center;
                    p {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
