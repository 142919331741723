.container-pagination {
    font-weight: 500;
    display: flex;
    gap: 10px;
    margin: auto;
    width: fit-content;
    align-items: center;
    line-height: 1 !important;
    margin-top: 30px;
    margin-bottom: 50px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    height: fit-content;
    @media (max-width: 767.98px) {
        gap: 7px;
    }
    li {
        display: block;
        a {
            display: block;
        }
    }
    li.page-item-pagination {
        a.page-link-pagination {     
            line-height: 1 !important;
            font-size: 19px;
            min-width: 35px;
            min-height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #555;
            @media (max-width: 767.98px) {
                min-width: 25px;
                min-height: 25px;         
            }
        }
    }
    li.previous-label-pagination,
    li.next-label-pagination {
        color: var(--main_color);
        a {
            div.label-box {
                position: relative;
                p.text-label {
                    @media (max-width: 767.98px){
                        display: none;
                    }
                }
                .pagination-icons {
                    position: absolute;
                    top: 50%;
                    &.disable {
                        color: #ddd;
                    }
                }
            }
        }
    }
    li.previous-label-pagination {
        a.previous-link-pagination {
            line-height: 1 !important;
            line-height: inherit;
            padding: 0;
            div.label-box {
                p.text-label {
                    padding-right:8px;
                }
                .pagination-icons {
                    transform: translateY(-50%) rotate(90deg);
                    right: -37px;
                }
            }
        }
    }

    li.next-label-pagination {
        a.next-link-pagination {
            div.label-box {
                p.text-label {
                    padding-left:8px;
                }
                .pagination-icons {
                    transform: translateY(-50%) rotate(-90deg);
                    left: -37px;
                }
            }
            line-height: 1 !important;
            padding: 0;
        }
    }
    li.break-pagination {
        line-height: inherit;
        a.break-link-pagination {
            line-height: 1 !important;
        }
    }
    li.active-pagination {
        background-color: var(--main_color);
        color: #fff;
    }
}