@keyframes loading {
    0% {
        transform: scale(1) rotate(0deg);
    }
    100% {
        transform: scale(0.3) rotate(360deg);
    }
}

@keyframes animate {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: rotate(45deg) translate(10px, 10px);
    }
    100% {
        opacity: 0;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes moveOverlay {
    0% {
        transform: translate(0%, 0%);
    }
    100% {
        transform: translate(180%, 0%);
    }
}

@keyframes moveOverlayMobile {
    0% {
        transform: translate(0%, 0%);
    }
    100% {
        transform: translate(60%, 0%);
    }
}
@keyframes moveOverlayFullScreen {
    0% {
        transform: translate(0%, 0%);
    }
    100% {
        transform: translate(400%, 0%);
    }
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@keyframes rotateccw {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes spin {
    0%,
    100% {
        box-shadow: 0.2em 0px 0 0px currentcolor;
    }
    12% {
        box-shadow: 0.2em 0.2em 0 0 currentcolor;
    }
    25% {
        box-shadow: 0 0.2em 0 0px currentcolor;
    }
    37% {
        box-shadow: -0.2em 0.2em 0 0 currentcolor;
    }
    50% {
        box-shadow: -0.2em 0 0 0 currentcolor;
    }
    62% {
        box-shadow: -0.2em -0.2em 0 0 currentcolor;
    }
    75% {
        box-shadow: 0px -0.2em 0 0 currentcolor;
    }
    87% {
        box-shadow: 0.2em -0.2em 0 0 currentcolor;
    }
}
