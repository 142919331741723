// sections
@forward "./sections/sortSection/SortSection";
@forward "./sections/sideBarSection/SideBarSection";
@forward "./sections/cartsSection/CartsSection";
//coursePageSections
@forward "./coursePageSections/courseContents/CourseContents";
@forward "./coursePageSections/courseLanding/CourseLanding";
@forward "./coursePageSections/courseMainCard/CourseMainCard";
@forward "./coursePageSections/courseRatings/CourseRatings";
// lectureSections
@forward "./lectureSections/lectureSideBar/LectureSideBar";
@forward "./lectureSections/lectureVideo/LectureVideo";

@use "./../../../helpers/mixin" as M;
@use "./../../../helpers/variables" as V;

main#courses-page,
main#course-item-page {
    @media (max-width: 767.98px) {
        margin-top: 96px;
    }
    div.container {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        position: relative;
        @media (max-width: 767.98px) {
            gap: 0px;
        }
        div.courses-left {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 30px;
            margin-top: 19px;
        }
    }
}

main#course-item-page {
    div.container {
        gap: 70px;
        @media (max-width: 767.98px) {
            flex-direction: column-reverse;
            gap: 0;
        }
        div.course-item-page-right {
            width: 100%;
        }
        h2 {
            font-size: 35px;
            @media (max-width: 991.98px) {
                margin: auto;
            }
        }
    }
}

main#lecture-item-page {
    margin-top: 30px;
    @media (max-width: 767.98px) {
        margin-top: 82px;
    }
    div.container {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        position: relative;
        @media (max-width: 767.98px) {
            flex-direction: column;
        }
        div.lecture-item-page-right {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 30px;
            margin-top: 19px;
        }

        div.lecture-item-page-left {
            margin-top: 21px;
            width: 344px;
            height: fit-content;
            margin-bottom: 30px;
            padding: V.$padding_section_courses;
            position: relative;
            @media (max-width: 767.98px) {
                margin-top: 0;
            }
        }
    }
}
