div.view-order-modal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    div.view-order-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1;
        span.view-order-header-right {
            flex-shrink: 0;
            white-space: nowrap;
            line-height: 1;
            font-size: 14px;
        }
        div.view-order-header-left {
            display: flex;
            align-items: center;
            .close-icon {
                opacity: 0.9;
                cursor: pointer;
                &:hover {
                    color: #d22b40;
                }
            }
        }
    }
    div.view-order-top {
        display: flex;
        flex-direction: column;
        gap: 5px;
        div.view-order-top-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
            p {
                color: var(--main_color);
                white-space: nowrap;
                font-size: 14px;
                font-weight: 600;
                color: var(--main_color);
            }
            div.view-order-id-value {
                display: flex;
                align-items: center;
                gap: 2px;
                span {
                    max-width: 300px;
                    @media (max-width: 767.98px) {
                        max-width: 275px;
                    }
                }
            }
        }
        ul.view-order-top-body {
            display: flex;
            flex-direction: column;
            gap: 2px;
            padding: 3px 5px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            border-radius: 8px;
            @at-root html.dark
                    div.view-order-modal
                    div.view-order-top
                    ul.view-order-top-body {
                background-color: var(--main_background_color);
            }
            li.user-item {
                display: flex;
                align-items: start;
                gap: 5px;
                font-weight: 500;
                .view-modal-copy-icon {
                    padding-top: 5px;
                }
                span.user-item-text {
                    max-width: 413px;
                    @media (max-width: 767.98px) {
                        max-width: 272px;
                    }
                }
            }
        }
    }
    div.view-order-middle {
        display: flex;
        flex-direction: column;
        gap: 3px;
        max-height: 147px;
        overflow-y: auto;
        div.view-courses-box {
            display: flex;
            flex-direction: column;
            gap: 3px;
            padding-bottom: 10px;
            div.user-course-item {
                border-bottom: none;
                div.item-right {
                    div.item-content {
                        p.title.ellipsis {
                            font-weight: 500;
                        }
                        div.text-wrap {
                            div.order-modal-course-price {
                                div.price-after-discount {
                                    font-weight: 600;
                                }
                                div.original-price {
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    div.view-order-bottom {
        display: flex;
        flex-direction: column;
        gap: 2px;
        div.view-order-bottom-items {
            display: flex;
            flex-direction: column;
            gap: 10px;
            div.view-order-bottom.item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                p.view-order-bottom-right {
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    font-weight: 600;
                    font-size: 14px;
                }
                span.view-order-bottom-left {
                    display: flex;
                    align-items: center;
                    line-height: 1;
                }
            }
        }
    }
}
.view-modal-title-box {
    font-size: 14px;
    font-weight: 600;
    color: var(--main_color);
}
