.react-datepicker-popper {
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.date-input {
    display: block;
    padding-inline-start: 30px;
    width: 100%;
    caret-color: transparent;
}
.react-datepicker-wrapper {
    width: 100%;
}
.add-user-modal.field-box.role-box.date-box {
    position: relative;
    .date-icon {
        position: absolute;
        top: 50%;
        right: 1px;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;
    }
}
.react-datepicker__navigation-icon.react-datepicker__navigation-icon--next {
    top: 5px;
}
