section#statistics-section {
    margin-top: 60px;
    margin-bottom: 50px;
    div.container {
        .statistics-title-section {
            margin: auto;
        }
        div.statistics.box {
            display: flex;
            justify-content: center;
            gap: 60px;
            margin-top: 33px;
            @media (max-width: 767.98px) {
                flex-direction: column;
                gap: 32px;
            }
            div.statistics-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 8px;
                padding: 20px 0;
                box-shadow: var(--box_shadow_two);
                position: relative;
                background-color: var(--cards_bg_color);
                min-width: 170px;
                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    background-color: var(--main_color);
                    transition: 1s;
                    height: 0;
                    transition: 0.6s;
                }
                &::before {
                    top: 0;
                    right: 0;
                }
                &::after {
                    left: 0;
                    bottom: 0;
                }
                &:hover::before,
                &:hover::after {
                    height: 100%;
                }
                .statistics-icon {
                    font-size: 35px;
                }
                span.statistics-numbers {
                    font-weight: 800;
                    font-size: 37px;
                }
                h3.statistics-title {
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--main_color);
                }
            }
        }
    }
}
