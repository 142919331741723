@forward "./units/VideoItem";

section#lecture-video {
    margin-bottom: 30px;
    overflow-x: hidden;
    width: 100%;
    p.category-video-name {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 5px;
    }
    h2.video-page-course-title {
        font-size: 21px;
        font-weight: 800;
        width: fit-content;
        position: relative;
        font-family: "VipHalaBold", sans-serif;
        color: var(--main_color);
        width: fit-content;
        overflow: hidden;
        width: 100%;
        max-width: 761px;
        @media (min-width: 767.98px) and (max-width: 991.98px) {
            max-width: 394px;
        }
        @media (max-width: 767.98px) {
            max-width: 400px;
            font-size: 18px;
            text-align: center;
            margin: auto;
        }
        &::before {
            display: none;
        }
    }
    div.video-container {
        margin-top: 10px;
        div.video-info-box {
            margin-top: 18px;
            h1.video-title {
                line-height: 1.2;
                font-size: 20px;
                max-width: 700px;
                font-weight: 600;
                position: relative;
                @media (max-width: 767.98px) {
                    font-size: 20px;
                }
            }
            div.description-box {
                div.description-box-top {
                    display: flex;
                    justify-content: space-between;
                }
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                gap: 7px;
                h2.title-description {
                    font-size: 18px;
                    font-weight: 500;
                    @at-root html.dark
                            section#lecture-video
                            div.video-container
                            div.video-info-box
                            div.description-box
                            h2.title-description {
                        font-weight: 400;
                    }
                }
                div.video-description {
                    font-weight: 500;
                    font-size: 14px;
                    padding-right: 9px;
                    border-radius: 12px;
                    background-color: var(--cards_bg_color);
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                    padding: 5px 15px;
                    @media (max-width: 767.98px) {
                        font-size: 13px;
                    }
                    span.createdAt-video {
                        margin-bottom: 5px;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
