div.categories-item {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    &:nth-child(odd) {
        flex-direction: row-reverse;
        @media (max-width: 767.98px) {
            flex-direction: column;
            align-items: center;
        }
    }
    div.categories-item-left.image-hand {
        width: 483px;
        height: 300px;
        border-radius: 30px;
        overflow: hidden;
        flex-shrink: 0;
        @media (max-width: 991.98px) {
            width: 360px;
            height: 291px;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    div.categories-item-right {
        margin-top: 50px;
        flex: 1;
        @media (max-width: 767.98px) {
            margin-top: 0;
        }
        h3.categories-item-title {
            font-size: 24px;
            font-weight: 700;
            color: var(--categories_header_color);
        }
        p.categories-item-description {
            margin-top: 10px;
            font-size: 20px;
        }
        .categories-item-link {
            display: block;
            width: fit-content;
            margin-top: 30px;
            font-size: 20px;
            padding: 5px 21px;
            border-radius: 9px;
            @media (max-width: 767.98px) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
