section#opinions-section {
    margin-top: 60px;
    div.container {
        .opinions-title {
            margin: auto;
        }
        div.opinions-box-home {
            margin-top: 40px;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            gap: 15px;
            @media (max-width: 767.98px) {
                gap: 0;
            }
            div.wrap-opinion-cart-slick {
                margin-bottom: 15px;
            }
        }
    }
}
