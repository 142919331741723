html.dark {
    div.react-select__control,
    div.react-select__control--is-focused {
        background-color: #3b3b3b;
        color: var(--main_paragraph_color);
        outline: none;
        div.react-select__value-container.react-select__value-container--has-value {
            background-color: #3b3b3b;
            color: var(--main_paragraph_color);
            div.react-select__single-value {
                background-color: #3b3b3b;
                color: #fff;
            }
            div.react-select__input-container {
                background-color: transparent;
                color: var(--main_paragraph_color);
                input.react-select__input {
                    background-color: #3b3b3b;
                    color: var(--main_paragraph_color);
                    &::after {
                        background-color: #3b3b3b;
                        color: var(--main_paragraph_color);
                    }
                }
            }
        }
        div.react-select__indicators {
            background-color: #3b3b3b;
            color: var(--main_paragraph_color);
            span.react-select__indicator-separator {
                background-color: #3b3b3b;
                color: var(--main_paragraph_color);
            }
            div.react-select__indicator.react-select__dropdown-indicator {
                background-color: #3b3b3b;
                color: var(--main_paragraph_color);
                svg {
                    background-color: #3b3b3b;
                    color: var(--main_paragraph_color);
                }
            }
        }
    }
    div.react-select__menu {
        background-color: #3b3b3b;
        color: var(--main_paragraph_color);
        div.react-select__menu-list {
            background-color: #3b3b3b;
            color: var(--main_paragraph_color);
            div.react-select__option {
                background-color: #3b3b3b;
                color: var(--main_paragraph_color);
                &:hover {
                    background-color: #2dd4be5b;
                }
                &--is-selected {
                    background-color: var(--main_color);
                    color: white;
                }
            }
        }
    }
}
div.react-select__control,
div.react-select__control--is-focused {
    border: 1px solid #ccc;
    height: 33px;
    min-height: 33px;
    div.react-select__value-container.react-select__value-container--has-value {
        height: 29px;
        min-height: 29px;
        align-items: center;
        line-height: 100%;
        div.react-select__single-value {
            line-height: 100%;
            line-height: 100%;
        }
        div.react-select__input-container {
            height: 27px;
            min-height: 27px;
            font-size: 18px;
            line-height: 100%;
            align-items: center;
            padding: 0px 8px;
            margin: 0;
            input.react-select__input {
                height: 15px;
                min-height: 15px;
                font-size: 18px;
                &::after {
                    height: 15px;
                    min-height: 15px;
                }
            }
        }
    }
    div.react-select__indicators {
        display: flex;
        align-items: center;
        height: 30px;
        min-height: 30px;
        span.react-select__indicator-separator {
            height: 15px;
            min-height: 15px;
        }
        div.react-select__indicator.react-select__dropdown-indicator {
            display: flex;
            align-items: center;
            height: 30px;
            min-height: 30px;
        }
    }
}
div.react-select__menu {
    max-height: calc(33px * 5);
    div.react-select__menu-list {
        max-height: calc(33px * 5);
        div.react-select__option {
            height: 33px;
            min-height: 33px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: start;
            line-height: 1;
        }
    }
}
