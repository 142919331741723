main#not-found-page,
main.error-page {
    div.container {
        min-height: calc(100dvh - 60px);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: -60px;
        div.not-found-box {
            box-shadow: var(--box_shadow_two_form);
            max-width: 500px;
            border-radius: 14px;
            padding: 15px;
            p {
                font-weight: 500;
                font-size: 18px;
            }
        }
    }
}
