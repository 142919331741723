section#recent-courses-section {
    margin-top: 20px;
    div.container {
        div.recent-top {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @media (max-width: 767.98px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                text-align: center;
                width: fit-content;
            }
            div.recent-top-left {
                @media (max-width: 767.98px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    width: fit-content;
                }
                p.recent-paragraph {
                    margin-top: 18px;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1;
                }
            }
            div.recent-top-right {
                a.recent-view-all {
                    padding: 10px 15px;
                }
                margin-bottom: 7px;
                @media (max-width: 767.98px) {
                    margin-bottom: 0;
                    margin-top: 28px;
                    display: none;
                }
            }
        }
        div.recent-middle {
            display: flex;
            &.larg-dir {
                @media (min-width: 991.98px) {
                    direction: ltr;
                }
            }
            justify-content: flex-start;
            gap: 20px;
            margin-top: 43px;
            margin-top: 43px;
            padding-top: 37px;
            overflow-x: auto;
            scrollbar-width: 0;
            padding-bottom: 30px;
            scrollbar-color: transparent transparent;
            scroll-snap-type: x proximity;
            flex-direction: row-reverse;
            @media (max-width: 767.98px) {
                margin-top: 22px;
                gap: 20px;
                flex-direction: column;
                margin: 22px auto;
                justify-content: center;
                width: fit-content;
            }
            &::-webkit-scrollbar {
                display: none;
            }
            &.smooth {
                scroll-behavior: smooth;
            }
        }
        div.recent-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row-reverse;
            @media (max-width: 767.98px) {
                display: none;
            }
            div.bullets-box {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row-reverse;
                gap: 3px;
                div.bullets-item {
                    width: 8px;
                    height: 8px;
                    background-color: var(--main_color);
                    background-color: var(--bullets_bg_color);
                    border-radius: 50%;
                    cursor: pointer;
                    &.active {
                        background-color: var(--main_color);
                    }
                }
            }
            .arrow {
                cursor: pointer;
                color: var(--main_color);
            }
        }
    }
    .recent-view-all-bottom {
        display: none;
        white-space: nowrap;
        @media (max-width: 767.98px) {
            display: block;
            margin: 0 auto 0;
            width: fit-content;
        }
    }
}
