div.review-course-items-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 222px;
    overflow-y: auto;
    width: 100%;
    div.review-course-item {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        padding: 2px 0;
        border-bottom: 1px solid #ddd;
        width: 100%;
        @at-root html.dark div.review-course-items-box div.review-course-item {
            border-bottom: 1px solid #dddddd3a;
            &:last-child {
                border-bottom: none;
            }
        }
        &:last-child {
            border-bottom: none;
        }
        div.item-right {
            display: flex;
            gap: 5px;
            width: 100%;
            div.image-hand {
                border-radius: 5px;
                width: 51px;
                height: 51px;
                overflow: hidden;
                flex-shrink: 0;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            div.item-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                .special-review-item-content-top {
                    display: flex;
                    justify-content: space-between;
                }
                p.title {
                    max-width: 300px;
                    font-weight: 500;
                    @media (max-width: 767.98px) {
                        max-width: 184px;
                    }
                }
                div.text-wrap {
                    display: flex;
                    gap: 5px;
                    transition: height 0.3s;
                    overflow: hidden;
                    span.review-comment {
                        max-width: 346px;
                        padding-bottom: 3px;
                        &.active {
                            max-width: none;
                        }
                        @media (min-width: 767.98px) and (max-width: 991.98px) {
                            max-width: 605px;
                        }
                        @media (max-width: 767.98px) {
                            max-width: 232px;
                            &.active {
                                max-width: none;
                            }
                        }
                    }
                }
            }
        }
        div.item-left {
            display: flex;
            height: fit-content;
            align-items: center;
            gap: 5px;
        }
    }
}
.fav-icon {
    color: #aaa;
    &.active {
        color: var(--main_paragraph_color, #001653) !important;
    }
    cursor: pointer;
}
