tr.table-body-row td .td-content {
    position: relative;
    span.span-cell:empty {
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24px;
            height: 1px;
            transform: translate(-50%, -50%);
            background-color: #aaa !important;
            border-radius: 2px;
        }
    }
}
.row-table-number:empty {
    border: none !important;
}
