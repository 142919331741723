div.video-course-wrapper {
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: fill !important;
    }
}
