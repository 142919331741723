h2.title-section {
    font-size: 40px;
    font-weight: 800;
    width: fit-content;
    position: relative;
    @media (max-width: 767.98px) {
        font-size: 30px;
    }
    span {
        color: var(--main_color);
    }
    &:before {
        content: "";
        position: absolute;
        bottom: -9px;
        border-radius: 3px;
        left: 50%;
        transform: translateX(-50%);
        background-image: var(--logo_gradient);
        width: 50%;
        height: 3px;
        transition: width 0.3s;
    }
    &:hover:before {
        width: 60%;
    }
}
