h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    padding: 0;
    margin: 0;
}
textarea {
    resize: vertical;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
th {
    font-weight: 400;
}
button {
    border: none;
    outline: none;
    cursor: pointer;
    display: block;
}
button,
img {
    user-select: none !important;
}
a {
    text-decoration: none;
    color: inherit;
}

input,
textarea {
    display: block;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-size: 18px;
    width: 100%;
}
