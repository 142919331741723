// sections
@forward "./adminCategoriesSections/AdminCategoriesSections";
@forward "./adminCoursesSections/AdminCoursesSections";
@forward "./adminSubcategoriesSection/AdminSubcategoriesSection";
@forward "./adminSummarySections/AdminSummarySections";
@forward "./adminUsersSections/AdminUsersSections";
@forward "./adminCouponsSections/AdminCouponsSections";
@forward "./adminOrdersSections/AdminOrdersSections";
// blocks
@forward "./blocks/SummaryPageCard";
@forward "./blocks/ListTable";
@forward "./blocks/ListRow";

.add-new-dashbord-item {
    min-width: 76px;
}