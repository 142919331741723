.loading-effect-cssloaders {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #fff;
    color: var(--main_color);
    position: relative;
    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        border-radius: 50%;
        transform: rotateX(70deg);
        animation: 1s spin linear infinite;
    }
    &:after {
        color: var(--main_paragraph_color, #001653);
        transform: rotateY(70deg);
        animation-delay: 0.4s;
    }
    &.larg {
        width: 48px;
        height: 48px;
    }
    &.btn {
        color: #fff;
        &:after {
            color: #001653;
        }
    }
}
