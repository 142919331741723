div.table-box {
    table.admin-table.courses-table {
        colgroup {
            col.rating-list.col-3 {
                width: auto;
                width: 500px;
            }
            col.rating-list.col-5 {
                width: 700px;
            }
        }
        thead {
            tr {
                th.rating-list.cell-header-5.cell-hrader-course {
                    width: 233px;
                }
            }
        }
    }
}
