a.hover-common-btn {
    --color: var(--main_color);
    --bg_gradient: var(--btn_gradient);
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6em;
    height: 2.4em;
    line-height: 2.5em;
    overflow: hidden;
    margin: 20px;
    font-size: 17px;
    z-index: 1;
    font-weight: 600;
    border: 2px solid var(--main_title_color);
    border-radius: 12px;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        background-image: var(--bg_gradient);
        width: 170px;
        height: 200px;
        z-index: -1;
        border-radius: 50%;
    }
    &:hover {
        color: var(--btn_text_color);
        border-color: var(--main_color);
    }
    &:before {
        top: 100%;
        left: 100%;
        transition: 0.3s all;
    }
    &:hover::before {
        top: -30px;
        left: -30px;
    }
}
