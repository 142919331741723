.video-react-fullscreen {
    .video-overlay {
        top: 20px;
        left: 15px;
        animation: moveOverlayFullScreen 60s ease-in-out infinite alternate;
        font-size: 14px;
    }
}
.video-overlay {
    position: absolute;
    top: 7px;
    left: 15px;
    background-color: rgba(255, 255, 255, 0.068);
    opacity: 0.5;
    padding: 1px;
    border-radius: 5px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 330px;
    z-index: 99999 !important;
    user-select: none !important;
    font-size: 12px;
    animation: moveOverlay 60s ease-in-out infinite alternate;
    @media (max-width: 991.98px) {
        top: 3px;
        animation: moveOverlayMobile 80s ease-in-out infinite alternate;
    }
}
.plyr {
    height: 431px;
    @media (min-width: 906px) and (max-width: 991.98px) {
        height: 377px;
        width: 463px;
    }
    @media (max-width: 767.98px) {
        height: 200px !important;
    }
    .plyr__poster {
        background-size: 100% 100%;
    }
}
.about-landing-box-start {
    .plyr {
        height: 463px !important;
        @media (min-width: 767.98px) and (max-width: 991.98px) {
            height: 463px !important;
            width: 100% !important;
        }
        @media (max-width: 767.98px) {
            height: 200px !important;
        }
    }
}
#lecture-video {
    .plyr {
        @media (min-width: 991.98px) and (max-width: 1120px) {
            height: 377px !important;
        }
        @media (min-width: 906px) and (max-width: 991.98px) {
            height: 377px !important;
            width: 472px !important;
        }
        @media (min-width: 767.98px) and (max-width: 906px) {
            height: 345px !important;
            width: 400px !important;
        }
    }
}
