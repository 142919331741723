div.category-sidebar-item {
    margin-top: 20px;

    div.category-sidebar-item-top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 3px;
        cursor: pointer;
        div.title-box {
            display: flex;
            gap: 3px;
            &.active {
                color: var(--main_color);
            }
            .title-category-icon {
                flex-shrink: 0;
            }
            h3.category-name {
                line-height: 1.3;
                font-size: 15px;
                font-weight: 800;
            }
        }
        .sidebar-icon-box {
            position: relative;
            flex-shrink: 0;
            width: 25px;
            .sidebar-icon {
                position: absolute;
                top: 0;
                left: 0;
                line-height: 1.3;
                color: var(--main_color);
                cursor: pointer;
                transform: rotate(90deg);
                transition: transform 0.3s;
                &.active {
                    transform: rotate(0deg);
                }
            }
        }
    }

    div.category-sidebar-item-bottom {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 14px;
        overflow: hidden;
        transition: height 0.3s;
        padding-right: 32px;
        div.sub-category-side-item {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 3px;
            &.active {
                p.category-name {
                    color: var(--main_color);
                    font-weight: 500;
                    &::before {
                        width: 30px;
                    }
                }
            }
            p.category-name {
                position: relative;
                font-size: 15px;
                font-weight: 300;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                white-space: nowrap;
                overflow-wrap: break-word;
                font-size: 14px;
                padding-bottom: 3px;
                &::before {
                    content: "";
                    position: absolute;
                    bottom: -3px;
                    right: 0;
                    z-index: 1200;
                    width: 0;
                    height: 5px;
                    background-color: var(--main_color);
                    transition: width 0.6s;
                    border-radius: 5px;
                }
            }
            span {
                color: var(--main_color);
                background-color: var(--main_background_color);
                padding: 1px 5px;
                border-radius: 9px;
                text-align: center;
                min-width: 56px;
            }
        }
    }
}
