section#popular-courses-section {
    margin-top: 15px;
    position: relative;
    z-index: 2;
    div.container {
        div.popular-top {
            margin: 30px auto 0;
            margin: 40px auto 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            p.popular-description {
                margin-top: 22px;
                font-size: 18px;
                font-weight: 500;
                line-height: 1;
                max-width: 550px;
            }
        }
        div.popular-middle {
            margin: 40px auto 0;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            gap: 20px;
            @media (max-width: 767.98px) {
                width: fit-content;
            }
            div.courses-card:nth-child(n + 4) {
                @media (max-width: 767.98px) {
                    display: none;
                }
            }
        }
        div.popular-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            margin-top: 20px;
            .popular-view-all {
                padding: 6px 15px;
                white-space: nowrap;
            }
        }
    }
}
