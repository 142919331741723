div.table-box {
    overflow: auto;
    width: fit-content;
    max-width: 920px;
    margin: auto;
    @media (min-width: 767.98px) and (max-width: 991.98px) {
        max-width: 719px;
    }
    @media (max-width: 767.98px) {
        max-width: 330px;
    }
    table.admin-table.courses-table {
        color: #777777;
        @at-root html.dark table.admin-table.courses-table {
            color: #fff;
        }
        colgroup {
            col.col-3 {
                width: 570px; //default grow to fill empty width of table's parent
            }
        }
        thead {
            tr {
                background-color: #f8f8f8;
                @at-root html.dark table.admin-table.courses-table thead tr {
                    background-color: #121212;
                }
            }
        }
        th,
        td {
            border-block: 1px solid #dddddd7a;
        }
        th {
            font-size: 14px;
            font-weight: 600;
            text-wrap: nowrap;
            white-space: nowrap;
            overflow-wrap: break-word;
            width: fit-content;
            padding: 15px 6px;
        }
        td {
            text-align: center;
            margin: auto;
            padding: 5px;
        }
    }
}
.row-table-number {
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 90%;
    margin: auto;
    line-height: 1.3;
    @at-root html.dark .row-table-number {
        border: 1px solid #777;
    }
}
