main#admin-add-course-page,
main#admin-add-Category-page,
main#admin-add-Subcategory-page {
    display: flex;
    justify-content: center;
    margin-top: 114px;
    @media (max-width: 767.98px){
        margin-top: 145px;
    }
}
section#add-course,
div.add-course {
    padding: 15px;
    max-width: 500px;
    height: fit-content;
    background-color: var(--main_background_color);
    box-shadow: var(--box_shadow_two_form);
    div.add-course-top {
        h1 {
            font-size: 19px;
            font-weight: 600;
        }
        p {
            font-size: 15px;
            font-weight: 500;
        }
    }
    div.add-course-bottom {
        form {
            margin-top: 15px;
            div.form-middle {
                div.field-box {
                    position: relative;
                }
                p.add-course-filed-description {
                    padding-top: 12px;
                    font-size: 13px;
                }
            }
            div.add-course-action-box {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-top: 15px;
                a {
                    color: var(--main_color);
                    font-weight: 500;
                }
            }
        }
    }
}
