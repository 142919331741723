.icon-field {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}

.icon-field-pass-view {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    opacity: 0.6;
}

.field-box {
    position: relative;
}

button.outline-common-btn,
a.outline-common-btn {
    border: 1px solid var(--main_color);
    padding: 5px 15px;
    border-radius: 5px;
    font-weight: 600;
    background-image: var(--logo_gradient);
    background-clip: text;
    color: transparent;
    transition: 0.3s;
    &:hover {
        box-shadow: 0px 1px 9px 0px var(--main_color);
    }
}

button.solid-common-btn,
a.solid-common-btn {
    background-image: var(--logo_gradient);
    padding: 5px 13px;
    border-radius: 5px;
    color: var(--btn_text_color);
    font-weight: 600;
    box-shadow: 0px 0 0 0px transparent;
    transition: 0.9s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    position: relative;
    &:hover {
        box-shadow: var(--box_shadow_four);
    }
}

.aqua-color {
    color: var(--main_color);
}

.font-vip {
    font-family: "VipHalaBold", sans-serif;
}

.elianto {
    font-family: "EliantoRegular", sans-serif;
}

.logo-text {
    font-size: 15px;
    @media (max-width: 767.98px) {
        font-weight: 900;
        font-size: 15px;
    }
}

.spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid transparent;
    border-top-color: #3498db;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
}

.input-padding {
    padding-right: 30px;
}

.go-home-link {
    margin-top: 15px;
    display: block;
} //

.hide {
    display: none !important;
}

.main-color {
    color: var(--main_color);
}

.errors-fire {
    margin-bottom: 20px;
}

.errors-fire-two {
    margin-bottom: 8px;
}

.errors-fire-three {
    margin-bottom: 10px;
}

.dashbord-title-pages {
    font-size: 20px !important;
    @media (max-width: 767.98px) {
        text-align: center;
        margin: auto;
    }
    &::before {
        content: none !important;
    }
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.trash-row-icon {
    border: 1px solid var(--main_color);
    border-radius: 5px;
    flex-shrink: 0;
    width: 27px;
    height: 27px;
    padding: 4px;
    color: var(--main_color);
    cursor: pointer;
    &:hover {
        border: 1px solid #d22b40;
        color: #d22b40;
    }
}

.btn-modal-box {
    display: flex;
    justify-content: space-between;
    button {
        width: 100px;
    }
}

.modal-msg {
    font-weight: 500;
    text-align: center;
    padding-bottom: 15px;
    min-width: 400px;
    @media (max-width: 767.98px) {
        min-width: 284px;
    }
}

.edit-btn-row {
    padding: 3px 13px !important;
}

.pointer-none {
    cursor: not-allowed !important;
    &:hover {
        box-shadow: none !important;
    }
}

div.complete-req-fields-box {
    p.complete-req-fields {
        font-size: 14px;
        font-weight: 500;
    }
}

.check-offline {
    margin: auto;
}

.select-none {
    user-select: none !important;
}

.countries-list-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .flag-label {
        width: 15px;
        height: 15px;
    }
    span.name-country-label {
    }
}

.btn-purchase-course {
    width: 100% !important;
}

.no-course-hint {
    font-weight: 500;
    margin-top: 20px;
    &.no-margin {
        margin-top: 0;
    }
}

div.pdf-for-user {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    span.pdf-name {
        max-width: 150px;
        @media (max-width: 767.98px) {
            font-size: 11px;
        }
    }
    .pdf-icon-download {
        cursor: pointer;
        @media (max-width: 767.98px) {
            width: 16px;
            height: 16px;
        }
        &.not-active {
            cursor: auto;
        }
    }
    &.not-active {
        cursor: auto;
    }
}

#lecture-video {
    .plyr {
        height: 431px;
    }
}

.font-12 {
    font-size: 12px;
}

.trash-icon {
    color: #aaa;
    cursor: pointer;
    &:hover {
        color: #d22b40;
    }
    &.pointer-none {
        cursor: not-allowed;
        &:hover {
            color: #aaa;
        }
    }
}
