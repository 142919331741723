body {
    .tm-preload {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background-color: var(--main_background_color, #fff);
        color: var(--main_paragraph_color, #001653);
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        z-index: 1000;
        font-size: 30px;
        .image-hand {
            width: 230px;
            height: 160px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .tm-preload-content-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            .loading-icon-effect {
                margin-top: 10px;
            }
        }
    }
}
