@forward "./unit/CartOrderItem";

section#order-section {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 30px;
    background-color: var(--main_background_color, #fff);
    @at-root html.dark section#order-section {
        background-color: var(--main_background_color, #121212);
    }
    @media (max-width: 767.98px) {
        flex-direction: column-reverse;
    }
    div.courses-in-cart-boxs {
        display: flex;
        flex-direction: column;
        gap: 10px;
        min-height: 500px;
        @media (max-width: 767.98px) {
            min-height: 70px;
            margin: auto;
        }
        div.payment-hint {
            width: 100%;
        }
        div.courses-in-cart-header {
            border-bottom: 1px solid #dddddd7a;
            .paragraph-header-cart-item {
                font-size: 14px;
                font-weight: 500;
                padding-bottom: 5px;
            }
        }
        div.courses-in-cart-body {
            display: flex;
            flex-direction: column;
            gap: 15px;
            max-height: 500px;
            overflow-y: auto;
        }
    }
    div.cart-order {
        box-shadow: var(--box_shadow_two_form);
        max-width: 310px;
        margin: 104px 0;
        border-radius: 14px;
        overflow: hidden;
        height: fit-content;
        @media (max-width: 767.98px) {
            margin: 77px auto 20px;
        }
        img {
            height: 200px;
            width: 100%;
        }
        div.cart-order-content {
            padding: 0 15px 15px;
            div.cart-order-content-top {
                h2.cart-title-order {
                    font-weight: 700;
                    font-size: 19px;
                    line-height: 1;
                    text-align: center;
                }
                p.cart-price-order {
                    margin-top: 10px;
                    display: block;
                    line-height: 1;
                    font-weight: 700;
                    font-size: 35px;
                    text-align: center;
                }
            }
            div.cart-order-content-bottom {
                h2.summary {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 1;
                    margin: 15px 0 7px;
                }
                div.cart-order-taxes,
                div.copoun-form-box,
                div.total-order-price,
                .checkout-btn {
                    margin: 8px 0;
                }
                div.cart-order-taxes {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        display: block;
                        line-height: 1;
                        font-weight: 700;
                        font-size: 23px;
                        text-align: center;
                    }
                }
                div.copoun-form-box {
                    form {
                        position: relative;
                        div.form-middle {
                            div.field-box {
                                div.field-wrapper {
                                    position: relative;
                                    #coupon {
                                        padding-inline-end: 132px;
                                        &.errors-fire {
                                            margin-bottom: 20px;
                                        }
                                    }
                                }
                            }
                        }
                        .apply-coupon-submit {
                            position: absolute;
                            left: 2px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
                div.total-order-price {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        display: block;
                        line-height: 1;
                        font-weight: 700;
                        font-size: 23px;
                        text-align: center;
                    }
                }
                .checkout-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 700;
                    width: 100%;
                    padding: 8px 0;
                    text-align: center;
                    height: 42px;
                }
                a.checkout-btn {
                    height: 42px;
                    padding: 3px 0;
                }
            }
        }
    }
}
div.radio-payment-options {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 5px;
    div.payment-option-wrapper {
        display: flex;
        justify-content: space-between;
        div.redio-payment-box {
            display: flex;
            flex-direction: row;
            gap: 5px;
            input[name="paymentMethod"] {
                width: fit-content;
            }
            label {
                span {
                    font-weight: 600;
                }
                &.active {
                    color: var(--main_color);
                }
            }
        }
        div.payment-paymob-icons-box {
            display: flex;
            gap: 3px;
        }
        .mezza-icon {
            width: 25px;
            height: 25px;
        }
    }
}
div#paypal-payment-info {
    h3 {
        font-size: 20px;
        font-weight: 800;
        text-align: center;
        margin-bottom: 15px;
    }
    div.paypal-payment-info-content {
        display: flex;
        flex-direction: column;
        gap: 15px;
        div.paypal-payment-info-card {
            background-color: var(--cards_bg_color);
            border-radius: 8px;
            box-shadow: var(--box_shadow_two);
            padding: 10px;
            height: auto;
            overflow: hidden;
            &.paypal-payment-info-card-right {
                width: 50%;
                @media (max-width: 767.98px) {
                    width: 100%;
                }
            }
            &.paypal-payment-info-card-left {
                width: 50%;
                @media (max-width: 767.98px) {
                    width: 100%;
                }
            }
            div {
                div.paypal-payment-title-top {
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    h4 {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 3px;
                    font-weight: 500;
                    font-size: 15px;
                    margin-top: 5px;
                    li {
                        display: flex;
                        align-items: start;
                        gap: 5px;
                        font-size: 14px;
                        &::before {
                            content: "";
                            display: block;
                            height: 4px;
                            width: 4px;
                            border-radius: 50%;
                            border: 1px solid #999;
                            margin-top: 10px;
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }
        div.payment-steps {
            display: flex;
            justify-content: space-between;
            gap: 15px;
            @media (max-width: 767.98px) {
                flex-direction: column;
            }
        }
    }
}
.payment-copy-btn {
    margin-top: 3px;
}
.paypal-payment-link {
    font-weight: 600;
    position: relative;
    &::before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #ccc;
        border-radius: 3px;
        position: absolute;
        bottom: 3px;
        right: 0;
    }
}
.payment-camera-icon {
    cursor: pointer;
    color: var(--main_color);
    flex-shrink: 0;
}
.paypal-payment-nesting-li {
    display: flex;
    align-items: start;
    gap: 5px;
    font-size: 14px;
}
