section#who-lecturer {
    div.image-about-plan-hand {
        width: 60%;
        height: 390px;
        margin: 30px auto 10px;
        @media (max-width: 767.98px){
        	width: 100%;
            height: 200px;
        }
        img {
            width: 100%;
            height: 100%;
        }
        .light-image {
            display: block;
        }
        .dark-image {
            display: none;                
        }
        @at-root html.dark section#who-lecturer div.image-about-plan-hand  .light-image {
            display: none;                
        }
        @at-root html.dark section#who-lecturer div.image-about-plan-hand  .dark-image {
            display: block;
        }
    }
}

div.aboutus-items-box {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 10px;
    div.aboutus-item {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        &:nth-child(odd) {
            flex-direction: row-reverse;
            @media (max-width: 767.98px) {
                flex-direction: column;
                align-items: center;
            }
        }
        div.aboutus-item-left.image-hand {
            width: 383px;
            height: 477px;
            border-radius: 12px;
            overflow: hidden;
            flex-shrink: 0;
            @media (max-width: 767.98px){
            	width: 100%;
                height: 430px;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }

        div.aboutus-item-right {
            ul.who-lecturer-list {
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-weight: 500;
                font-size: 15px;
                li.who-lecturer-list-item {
                    background-color: var(--cards_bg_color);
                    border-radius: 8px;
                    box-shadow: var(--box_shadow_two);
                    padding: 10px;
                    height: auto;
                    overflow: hidden;
                }
            }
        }
    }
}
