@forward "./blocks/RatingsListTable";
@forward "./blocks/RatingsListRow";
@forward "./units/ImageUploadCourses";
@forward "./units/ReviewCourseItem";

section#edit-course-sections {
    margin-bottom: 30px;
    @media (max-width: 767.98px) {
        margin-top: 93px;
    }
    div.user-profile-sections,
    section#user-profile-sections {
        @media (max-width: 767.98px) {
            margin-top: 10px !important;
        }
    }
}
div.chaprers--courses-box {
    background-color: rgba(45, 212, 190, 0.1215686275);
    border-radius: 8px;
    padding: 10px;
    div.chaprers--courses-box-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
            font-weight: 600;
        }
        button {
            display: flex;
            align-items: center;
            gap: 5px;
            background-color: transparent;
            font-weight: 600;
            .edit-icon {
                font-weight: 600;
            }
        }
    }
}
div.chaprers-courses-box-bottom {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 258px;
    overflow-y: auto;
    padding-left: 5px;
    &::-webkit-scrollbar {
        width: 8px;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--main_color);
        border-radius: 4px;
        box-shadow: 0 0 2px 1px #ccc inset;
    }
}
div.chapter-item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(45, 212, 190, 0.445);
    border-radius: 5px;
    padding: 7px 10px;
    cursor: grab;
    @at-root html.dark div.chapter-item-row {
        background-color: var(--main_background_color);
    }
    div.chapter-item-row-left {
        display: flex;
        align-items: center;
        gap: 5px;
        p {
            font-weight: 500;
        }
    }
    div.chapter-item-row-right {
        display: flex;
        align-items: center;
        gap: 5px;
        span.free-status,
        span.publish-status {
            border-radius: 8px;
            padding: 2px 8px;
            font-size: 12px;
            font-weight: 500;
        }
        span.free-status {
            background-color: #1aae9c;
            color: #fff;
        }
        span.publish-status {
            background-color: #212b43;
            color: #fff;
        }
        @at-root html.dark
                div.chapter-item-row
                div.chapter-item-row-right
                span.free-status {
            background-color: var(--cards_bg_color);
        }
        @at-root html.dark
                div.chapter-item-row
                div.chapter-item-row-right
                span.publish-status {
            background-color: var(--cards_bg_color);
        }
    }
    a,
    button.edit-button,
    button.edit-button {
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
    a {
        width: 20px;
        height: 20px;
    }
}
.hint-drag-drop {
    margin-top: 8px;
    font-size: 14px;
}
