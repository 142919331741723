@use "./../helpers/theme-variables_aqua" as *;

html {
    // light colors
    --main_title_color: #{$main_title_color};
    --main_paragraph_color: #{$main_paragraph_color};
    --main_background_color: #{$main_background_color};
    --main_color: #{$main_color};
    --main_color_opacity: #{$main_color_opacity};
    --header_section_h2_color: #{$header_section_h2_color};
    --btn_text_color: #{$btn_text_color};
    --categories_header_color: #{$categories_header_color};
    --hero_title_color: #{$hero_title_color};
    --hero_paragraph_color: #{$hero_paragraph_color};
    // gradient
    --menu_gradient: #{$menu_gradient};
    --section_gradient: #{$section_gradient};
    --title_gradient: #{$title_gradient};
    --logo_gradient: #{$logo_gradient};
    --btn_gradient: #{$btn_gradient};
    // cards
    --cards_bg_color: #{$cards_bg_color};
    // levels
    --level_bg_advanced: #{$level_bg_advanced};
    --level_bg_medium: #{level_bg_medium};
    --level_bg_beginner: #{$level_bg_beginner};
    --level_color: #{$level_color};
    // quotes section
    --quotes_icon_color: #{$quotes_icon_color};
    // bullets
    --bullets_bg_color: #{$bullets_bg_color};
    // border-theme-icon
    --border_theme_icon: #{$border_theme_icon};
    // courses page
    --section_bg_color: #{$section_bg_color};
    --sidebar_mobile_bg_color: #{$sidebar_mobile_bg_color};
    // footer
    --logo_footer_color: #{$logo_footer_color};
    --footer_bg_color: #{$footer_bg_color};
    --footer_Text_color: #{$footer_Text_color};
    --footer_link_bg_color: #{$footer_link_bg_color};
    --footer_link_color: #{$footer_link_color};
    --footer_border_color: #{$footer_border_color};
    --footer_copy_right_color: #{$footer_copy_right_color};
    // box shadow
    --box_shadow_one: #{$box_shadow_one};
    --box_shadow_two: #{$box_shadow_two};
    --box_shadow_three: #{$box_shadow_three};
    --box_shadow_four: #{$box_shadow_four};
    --box_shadow_two_form: #{$box_shadow_two_form};
    // brands -footer
    --facebook_color: #{$facebook_color};
    --whatsapp_color: #{$whatsapp_color};
    --youtube_color: #{$youtube_color};
    // Depends on other


}

html.dark {
    --main_color: #{$main_color_dark};
    --main_title_color: #{$main_title_color_dark};
    --main_paragraph_color: #{$main_paragraph_color_dark};
    --main_background_color: #{$main_background_color_dark};
    --hero_paragraph_color: #{$hero_paragraph_color_dark};

    --categories_header_color: #{$categories_header_color_dark};
    --btn_text_color: #{$btn_text_color_dark};
    // gradient
    --menu_gradient: #{$menu_gradient_dark};
    --section_gradient: #{$section_gradient_dark};
    --title_gradient: #{$title_gradient_dark};
    // cards
    --cards_bg_color: #{$cards_bg_color_dark};
    // quotes section
    --quotes_icon_color: #{$quotes_icon_color_dark};
    // bullets
    --bullets_bg_color: #{$bullets_bg_color_dark};
    // border-theme-icon
    --border_theme_icon: #{$border_theme_icon_dark};
    // courses page
    --sidebar_mobile_bg_color: #{$sidebar_mobile_bg_color_dark};
    // footer color
    --logo_footer_color: #{$logo_footer_color_dark};
    --footer_bg_color: #{$footer_bg_color_dark};
    --footer_Text_color: #{$footer_Text_color_dark};
    --footer_copy_right_color: #{$footer_copy_right_color_dark};

    // box shadow
    --box_shadow_one: #{$box_shadow_one_dark};
    --box_shadow_two: #{$box_shadow_two_dark};
    --box_shadow_three: #{$box_shadow_three_dark};
    --box_shadow_four: #{$box_shadow_four_dark};
    --box_shadow_two_form: #{$box_shadow_two_form_dark};
    // text shadow
}

body {
    color: var(--main_paragraph_color, #001653);
    background-color: var(--main_background_color, #fff);
}