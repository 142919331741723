$form-gap: 12px;
div.add-user-modal.form-box {
    div.add-user-modal.form-header {
        div.add-user-modal.form-header-left {
            h2.add-user-modal.form-header-title {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 5px;
            }
        }
    }
    form {
        display: flex;
        flex-direction: column;
        gap: $form-gap;
        div.add-user-modal.form-top {
            display: flex;
            gap: $form-gap;
            div.add-user-modal.field-box.first-name-box {
                width: 100%;
            }
            div.add-user-modal.field-box.last-name-box {
                width: 100%;
            }
        }
        div.add-user-modal.form-middle {
            display: flex;
            gap: $form-gap;
            div.add-user-modal.field-box.user-name-box {
                width: 100%;
            }
            div.add-user-modal.field-box.email-box {
                width: 100%;
            }
        }
        div.add-user-modal.form-bottom {
            display: flex;
            flex-direction: column;
            gap: $form-gap;
        }
    }
}
