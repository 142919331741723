@forward "./units/CategoriesItem";

section#categories-section {
    margin-top: 20px;
    div.container {
        .categories-title {
            margin: auto;
        }
        div.categories-box {
            display: flex;
            flex-direction: column;
            gap: 50px;
            margin-top: 40px;
        }
    }
}
