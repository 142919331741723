div.edit-field {
    background-color: #2dd4be1f;
    border-radius: 8px;
    padding: 10px;
    max-width: 100%;
    @at-root html.dark div.edit-field {
        background-color: var(--cards_bg_color);
    }
    div.edit-top {
        display: flex;
        gap: 5px;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;
        h4 {
            font-size: 16px;
            font-weight: 600;
        }
        button.edit-button {
            font-size: 14px;
            font-weight: 600;
            background-color: transparent;
            display: flex;
            align-items: center;
            gap: 5px;
            .edit-btn-content {
                font-size: 14px;
                font-weight: 600;
                background-color: transparent;
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }
    }
    div.edit-bottom {
        div.current-value {
            margin-top: 5px;
            display: block;
            &.not-active {
                display: none;
            }
        }
        div.edit-form-wrapper {
            margin-top: 15px;
            display: none;
            &.active {
                display: block;
            }
        }
        form {
            display: flex;
            flex-direction: column;
            gap: 15px;
            div.form-middle {
                display: flex;
                flex-direction: column;
                gap: 15px;
                div.field-box {
                    div.field-wrapper {
                        position: relative;
                    }
                }
            }
        }
    }
}

.apply-profile-submit {
    width: 100px;
    align-self: flex-end;
}

.uplaod-btn {
    align-self: flex-end;
    margin-top: 15px;
}
