.field-wrapper {
    .quill {
        margin: auto;
        max-width: 431px;
        @media (min-width: 767.98px) and (max-width: 991.98px) {
            max-width: 700px;
        }
        @media (max-width: 767.98px) {
            max-width: 310px;
        }
    }
}
.form-middle {
    &.video-modal-form {
        .field-wrapper {
            &.description-field-wrapper {
                .quill {
                    max-width: 441px;
                    @media (min-width: 767.98px) and (max-width: 991.98px) {
                        max-width: 364px;
                    }
                    @media (max-width: 767.98px) {
                        max-width: 309px;
                    }
                }
            }
        }
    }
}

.quill-custom {
    background-color: #fff;
    max-width: 500px;
    @at-root html.dark .quill-custom {
        color: #212b43;
        background-color: transparent !important;
        color: #fff !important;
    }
}

html.dark,
html {
    .ql-snow.ql-toolbar button:hover,
    .ql-snow .ql-toolbar button:hover,
    .ql-snow.ql-toolbar button:focus,
    .ql-snow .ql-toolbar button:focus,
    .ql-snow.ql-toolbar button.ql-active,
    .ql-snow .ql-toolbar button.ql-active,
    .ql-snow.ql-toolbar .ql-picker-label:hover,
    .ql-snow .ql-toolbar .ql-picker-label:hover,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active,
    .ql-snow.ql-toolbar .ql-picker-item:hover,
    .ql-snow .ql-toolbar .ql-picker-item:hover,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
        color: var(--main_color);
    }
    .ql-snow.ql-toolbar button:hover .ql-fill,
    .ql-snow .ql-toolbar button:hover .ql-fill,
    .ql-snow.ql-toolbar button:focus .ql-fill,
    .ql-snow .ql-toolbar button:focus .ql-fill,
    .ql-snow.ql-toolbar button.ql-active .ql-fill,
    .ql-snow .ql-toolbar button.ql-active .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
    .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
        fill: var(--main_color);
    }
    .ql-snow.ql-toolbar button:hover .ql-stroke,
    .ql-snow .ql-toolbar button:hover .ql-stroke,
    .ql-snow.ql-toolbar button:focus .ql-stroke,
    .ql-snow .ql-toolbar button:focus .ql-stroke,
    .ql-snow.ql-toolbar button.ql-active .ql-stroke,
    .ql-snow .ql-toolbar button.ql-active .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
    .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
    .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
    .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
    .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
    .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
    .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
        stroke: var(--main_color);
    }
}

html.dark {
    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button,
    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button,
    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button,
    .ql-snow.ql-toolbar .ql-picker-label,
    .ql-snow .ql-toolbar .ql-picker-label,
    .ql-snow.ql-toolbar .ql-picker-label,
    .ql-snow .ql-toolbar .ql-picker-label,
    .ql-snow.ql-toolbar .ql-picker-item,
    .ql-snow .ql-toolbar .ql-picker-item,
    .ql-snow.ql-toolbar .ql-picker-item,
    .ql-snow .ql-toolbar .ql-picker-item {
        color: rgb(255, 255, 255);
    }
    .ql-snow.ql-toolbar button .ql-fill,
    .ql-snow .ql-toolbar button .ql-fill,
    .ql-snow.ql-toolbar button .ql-fill,
    .ql-snow .ql-toolbar button .ql-fill,
    .ql-snow.ql-toolbar button .ql-fill,
    .ql-snow .ql-toolbar button .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item .ql-fill,
    .ql-snow.ql-toolbar button .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar button .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar button .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item .ql-stroke.ql-fill {
        fill: rgb(255, 255, 255);
    }
    .ql-snow.ql-toolbar button .ql-stroke,
    .ql-snow .ql-toolbar button .ql-stroke,
    .ql-snow.ql-toolbar button .ql-stroke,
    .ql-snow .ql-toolbar button .ql-stroke,
    .ql-snow.ql-toolbar button .ql-stroke,
    .ql-snow .ql-toolbar button .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-label .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-label .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-label .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-label .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-item .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-item .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-item .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-item .ql-stroke,
    .ql-snow.ql-toolbar button .ql-stroke-miter,
    .ql-snow .ql-toolbar button .ql-stroke-miter,
    .ql-snow.ql-toolbar button .ql-stroke-miter,
    .ql-snow .ql-toolbar button .ql-stroke-miter,
    .ql-snow.ql-toolbar button .ql-stroke-miter,
    .ql-snow .ql-toolbar button .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-label .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-label .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-label .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-label .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-item .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-item .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-item .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-item .ql-stroke-miter {
        stroke: rgb(255, 255, 255);
    }

    .ql-picker-options {
        background-color: var(--cards_bg_color) !important;
    }
}

.ql-container {
    font-family: "Tajawal";
}
.ql-editor {
    padding: 5px;
    p {
        font-family: "Tajawal";
    }
}

.ql-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.ql-formats {
    width: fit-content !important;
    padding: 2px !important;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0 !important;
    span {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.ql-font-Tajawal {
    font-family: "Tajawal", sans-serif;
}

.quill {
    .ql-container.ql-bubble.ql-disabled {
        .ql-editor {
            padding: 0;
        }
    }
}

.ql-editor.ql-blank::before {
    color: #808080;
    content: attr(data-placeholder);
    font-style: italic;
    left: auto;
    pointer-events: none;
    position: absolute;
    top: 1px;
    right: 5px;
    font-size: 18px;
    font-style: normal;
}
