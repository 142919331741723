tr.table-body-row.courses-table-row {
    td.categories-list.cell-row-3.cell-row-course {
        div.td-content {
            div.td-content-left {
                span.ellipsis {
                    max-width: 274px;
                    width: auto;
                }
            }
        }
    }
    td.categories-list.cell-row-5.cell-row-course {
        div.td-content {
            padding: 5px 8px;
            width: 120px;
        }
    }
}
