tr.table-body-row.courses-table-row {
    td.rating-list.cell-row-3.cell-row-course {
        div.td-content,
        a.td-content {
            div.td-content-left {
                span.ellipsis {
                    width: 90px !important;
                }
            }
        }
    }
    td.rating-list.cell-row-5.cell-row-course {
        div.td-content {
            width: 270px;
            text-align: start;
            cursor: pointer;
            overflow: hidden;
            word-break: break-all;
            span.ellipsis {
                &.show-comment {
                    overflow: initial;
                    text-overflow: initial;
                    white-space: initial;
                }
            }
        }
    }
    td.rating-list.cell-row-6.cell-row-course {
        div.td-content {
            max-width: 70px;
            width: 88px;
            padding: 5 10px;
        }
    }
    td.rating-list.cell-row-10.cell-row-course {
        div.td-content {
            width: 65px;
            justify-content: center;
            gap: 6px;
        }
    }
}
