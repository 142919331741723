section#who-lecturer-gallary {
    margin: 40px 0 30px;
    div.container {
        div.who-lecturer-gallary-top {
            display: flex;
            gap: 30px;
            @media (max-width: 767.98px) {
                flex-direction: column;
            }
            div.who-lecturer-gallary-image {
                border-radius: 12px;
                overflow: hidden;
                flex-shrink: 0;
                text-align: center;
                @media (max-width: 767.98px) {
                    height: 300px;
                    width: 100%;
                }
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
            div.who-lecturer-gallary-image-two-hand {
                width: calc(30% - 15px);
                height: 500px;
                @media (max-width: 767.98px) {
                    width: 100%;
                }
            }
            div.who-lecturer-gallary-image-hand {
                flex: 1;
                height: 500px;
            }
        }
    }
}
