section.summary-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
    gap: 10px;
    margin-top: 5px;
    div.summary-cards-item {
        background-color: var(--cards_bg_color);
        display: flex;
        flex-direction: column;
        gap: 7px;
        width: 300px;
        padding: 10px;
        box-shadow: 0px 10px 15px -1px rgba(0, 0, 0, 0.1),
            0px 4px 6px 2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        @media (max-width: 767.98px) {
            margin: auto;
        }
        div.summary-cards-item-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;
            p.right {
                font-size: 19px;
                line-height: 1;
                font-weight: 600;
                color: var(--main_title_color);
            }
            div.left {
                p {
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    font-weight: 600;
                    &.green {
                        color: green;
                        @at-root html.dark
                                section.summary-cards
                                div.summary-cards-item
                                div.summary-cards-item-top
                                div.left
                                p.green {
                            color: rgb(1, 223, 1);
                        }
                    }
                    &.red {
                        color: #d22b40;
                        @at-root html.dark
                                section.summary-cards
                                div.summary-cards-item
                                div.summary-cards-item-top
                                div.left
                                p.red {
                            color: #f53b53;
                        }
                    }
                    &.yellow {
                        color: #e4b007;
                        @at-root html.dark
                                section.summary-cards
                                div.summary-cards-item
                                div.summary-cards-item-top
                                div.left
                                p.yellow {
                            color: #f0be1c;
                        }
                    }
                    span {
                        font-size: 12px;
                        font-weight: 400;
                        color: var(--main_title_color);
                    }
                }
            }
        }
        div.summary-cards-item-middle {
            display: flex;
            flex-direction: column;
            gap: 1px;
            line-height: 1;
            margin-top: 2px;
            p.top {
                font-size: 38px;
                line-height: 1;
                font-weight: 500;
                color: var(--main_color);
            }
            span.bottom {
                line-height: 1;
                font-size: 12px;
                color: var(--main_title_color);
            }
        }
        div.summary-cards-item-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;
            a.right {
                line-height: 1;
                font-size: 14px;
                position: relative;
                font-weight: 500;
                color: var(--main_title_color);
                &::before {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: #ccc;
                    border-radius: 3px;
                    position: absolute;
                    bottom: -3px;
                    right: 0;
                }
            }
            div.left {
                line-height: 1;
                padding: 6px 6px 5px;
                border-radius: 6px;
                &.green {
                    color: green;
                    background-color: rgba(0, 128, 0, 0.288);
                    @at-root html.dark
                            section.summary-cards
                            div.summary-cards-item
                            div.summary-cards-item-bottom
                            div.left.green {
                        color: rgb(1, 223, 1);
                        background-color: rgba(1, 223, 1, 0.288);
                    }
                }
                &.red {
                    color: #d22b40;
                    background-color: hsla(352, 66%, 50%, 0.288);
                    @at-root html.dark
                            section.summary-cards
                            div.summary-cards-item
                            div.summary-cards-item-bottom
                            div.left.red {
                        color: #f53b53;
                        background-color: hsla(352, 90%, 60%, 0.288);
                    }
                }
                &.yellow {
                    color: #e4b007;
                    background-color: hsla(46, 80%, 52%, 0.288);
                    @at-root html.dark
                            section.summary-cards
                            div.summary-cards-item
                            div.summary-cards-item-bottom
                            div.left.yellow {
                        color: #f0be1c;
                        background-color: hsla(46, 80%, 52%, 0.288);
                    }
                }
            }
        }
    }
}
.percentage-box {
    display: flex;
    align-items: center;
    gap: 2px;
    line-height: 1;
    .arrow-summary-icon {
        font-weight: 600;
        &.up {
            transform: rotate(-90deg);
        }
        &.down {
            transform: rotate(90deg);
        }
        &.green {
            color: green;
            @at-root html.dark .percentage-box .arrow-summary-icon.green {
                color: rgb(1, 223, 1);
            }
        }
        &.red {
            color: #d22b40;
            @at-root html.dark .percentage-box .arrow-summary-icon.red {
                color: #f53b53;
            }
        }
        &.yellow {
            color: #e4b007;
            @at-root html.dark .percentage-box .arrow-summary-icon.yellow {
                color: #f0be1c;
            }
        }
    }
    p {
        &.green {
            color: green;
            @at-root html.dark .percentage-box p.green {
                color: rgb(1, 223, 1);
            }
        }
        &.red {
            color: #d22b40;
            @at-root html.dark .percentage-box p.red {
                color: #f53b53;
            }
        }
        &.yellow {
            color: #e4b007;
            @at-root html.dark .percentage-box p.yellow {
                color: #f0be1c;
            }
        }
    }
}
.all-user-icon {
    line-height: 1;
}