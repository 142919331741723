div.custom-select-menu {
    position: relative;
  div.select {
    border: 1px solid #ccc;
    position: relative;
    padding: 3px 8px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    width: 106px;
    cursor: pointer;
    .arrow {
      line-height: 1;
      display: flex;
      align-items: center;
      line-height: 1;
      svg {
        color: var(--main_color);
        transition: transform 0.3s;
        &.active {
          transform: rotate(180deg);
        }
      }
    }
    span {
      display: block;
      line-height: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  ul.menu {
    margin-top: 1px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: height 0.3s, margin 0.3s;
    position: absolute;
    width: 100%;
    top: 100%;
    right: 0;
    z-index: 800;
    &.active {
        border: 1px solid #bbb;
    }
    li.option {
      padding: 5px 8px;
      background-color: #fff;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      cursor: pointer;
      @at-root html.dark div.custom-select-menu ul.menu li.option {
        background-color:  #121212;
        &:hover {
          background-color: var(--main_color);
          color: #fff;
        }
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:hover {
        background-color: var(--main_color);
        color: #fff;
      }
    }
  }
  .before-option {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    flex-shrink: 0;
    &.color-main-color {
      background-color: var(--main_color);
    }
    &.color-gray {
      background-color: #ccc;
    }
  }
}