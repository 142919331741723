@use "./../../helpers/variables" as V;

.footer {
    background-color: var(--footer_bg_color);
    padding: 33px 0 0;
    @media (max-width: 767px) {
        text-align: center;
    }
    .container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 40px;
    }
    .box {
        h2 {
            color: var(--logo_footer_color);
            font-size: 22px;
            margin: 0 0 20px;
            word-spacing: -5px;
        }
        .social {
            display: flex;
            @media (max-width: 767px) {
                justify-content: center;
            }
            li {
                margin-left: 10px;
                a {
                    background-color: var(--footer_link_bg_color);
                    color: var(--footer_link_color);
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 50px;
                    font-size: 20px;
                    transition: 0.3s;
                    border-radius: 6px;
                }
            }
            .facebook:hover {
                background-color: var(--facebook_color);
            }
            .twitter:hover {
                background-color: var(--whatsapp_color);
            }
            .youtube:hover {
                background-color: var(--youtube_color);
            }
        }
        .text {
            line-height: 2;
            color: var(--footer_Text_color);
            margin-top: 7px;
        }
        .links {
            li {
                display: flex;
                align-items: center;
                gap: 3px;
                padding: 11px 0;
                transition: 0.3s;
                .double-arrow-icons {
                    color: var(--main_color);
                }
                &:not(:last-child) {
                    border-bottom: 1px solid var(--footer_border_color);
                }
                &:hover {
                    padding-left: 10px;
                    a {
                        color: var(--footer_copy_right_color);
                    }
                }
                a {
                    color: var(--footer_Text_color);
                    transition: var(--main-transition);
                }
            }
        }
        .line {
            display: flex;
            align-items: center;
            color: var(--footer_Text_color);
            margin-bottom: 19px;
            gap: 10px;
            @media (max-width: 767px) {
                flex-direction: column;
            }
            .footer-icons-columns {
                font-size: 25px;
                color: var(--main_color);
                margin-right: 10px;
                @media (max-width: 767px) {
                    margin-right: 0;
                    margin-bottom: 15px;
                }
            }
            .info {
                line-height: 1.7;
                flex: 1;
                span {
                    display: block;
                    width: fit-content;
                }
            }
        }
    }
    .copyright {
        border-bottom: 1px solid var(--footer_border_color);
        padding: 16px 0;
        text-align: center;
        color: var(--footer_copy_right_color);
        margin: 19px 0 0;
    }
    div.build-with {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        text-align: center;
        color: white;
        padding: 10px;
        div.build-with-links {
            display: flex;
            align-items: center;
            gap: 7px;
            a {
                display: flex;
                align-items: center;
                gap: 5px;
                &:hover {
                    color: var(--main_color);
                }
            }
        }
    }
}
