main#lecture-item-page {
    div.content-unit {
        background-color: var(--cards_bg_color);
        div.content-unit-top {
            padding: 4px 8px;
            gap: 3px;
            background-color: var(--cards_bg_color);
            word-break: break-all;
            div.chapter-title-box {
                h3 {
                    font-size: 16px;
                    font-weight: 500;
                    overflow: hidden;
                    word-break: break-all;
                    max-width: 276px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-height: 1.6;
                    max-height: calc(1.6 * 2);
                    @media (max-width: 767.98px) {
                    }
                }
                div.chapter-info {
                    display: flex;
                    gap: 5px;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .icon-arrow {
                background-color: #fff;
                border-radius: 50%;
                padding: 5px;
                width: 28px;
                height: 28px;
                flex-shrink: 0;
                color: #212b43;
            }
        }
        div.content-unit-bottom {
            &.active {
                margin: 8px;
                margin-top: 10px;
            }
            a.title-item-video,
            div.title-item-video {
                padding: 5px 12px;
                flex-direction: row;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
                background-color: var(--main_background_color);
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                align-items: start;
                .pdf-for-user {
                    justify-content: space-between;
                    width: 100%;
                    padding-top: 5px;
                    padding-left: 0;
                    .pdf-name {
                        max-width: 235px;
                    }
                    font-size: 14px;
                }
                .title-item-video-inside-box {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .item-title-box {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        p {
                            font-size: 14px;
                            max-width: 235px;
                            &.active {
                                color: var(--main_color);
                            }
                            @media (max-width: 767.98px) {
                            }
                        }
                    }
                }
                @media (max-width: 767.98px) {
                }
                .play-icon {
                    width: 27px;
                    height: 27px;
                    @media (max-width: 767.98px) {
                    }
                }
            }
        }
    }
}
