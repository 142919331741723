div.progress-bar-image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    div.progress-bar-image-child {
        background-color: #05a08b8c;
        height: 100%;
    }
}
