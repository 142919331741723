@forward "./sections/aboutLanding/AboutLanding";
@forward "./sections/whoLecturer/WhoLecturer";
@forward "./sections/whoLecturerGallary/WhoLecturerGallary";

main#about-us-page {
    margin-top: 30px;
    @media (max-width: 767.98px) {
        margin-top: 86px;
    }
    h2.about-landing-why-title {
        font-size: 18px;
        margin-top: 5px;
        &::before {
            display: none;
        }
    }
}
div.about-secount-title-box {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 50px;
    margin-bottom: 10px;
    &.one {
        margin-top: 0px;
    }
}
