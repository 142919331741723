div.video-list-table.table-box {
    table.video-list-table.admin-table.courses-table {
        td {
            padding: 8px;
        }
        colgroup {
            col.video-list-table.col-3 {
                width: 650px;
                @media (max-width: 767.98px) {
                    max-width: 150px;
                }
            }
        }
        thead {
            tr {
                th.video-list-table.cell-header-3.cell-hrader-course {
                    max-width: 350px;
                    width: auto;
                    @media (max-width: 767.98px) {
                        max-width: 150px;
                    }
                }
            }
        }
    }
}
