div.upload-file-component {
    div.dropzone {
        border: 2px dashed #ccc;
        border-radius: 4px;
        text-align: center;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
    }
    div.file-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        .header-video-table {
            display: flex;
            gap: 15px;
            border-bottom: 1px solid #ddd;
            text-align: center;
            @at-root html.dark
                    div.upload-file-component
                    div.file-list
                    .header-video-table {
                border-bottom: 1px solid #777;
            }
            div.column {
                font-weight: 600;
            }
        }
        div.file-item {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #ddd;
            @at-root html.dark
                    div.upload-file-component
                    div.file-list
                    div.file-item {
                border-bottom: 1px solid #777;
            }
            &:last-child {
                border-bottom: none;
            }
            .body-video-table {
                display: flex;
                gap: 15px;
            }
            div.column.progress-column {
                div.progress-box {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    width: 80%;
                    div.progress-bar {
                        background-color: #ccc;
                        border-radius: 8px;
                        height: 8px;
                        overflow: hidden;
                        position: relative;
                        flex: 1;
                        div.progress {
                            background-color: var(--main_color);
                            height: 100%;
                            border-radius: 8px;
                            &.progress-error {
                                background-color: #d22b40;
                            }
                            &.progress-done {
                                background-color: #4caf50;
                            }
                        }
                    }
                    span {
                        display: block;
                        width: 30px;
                        font-size: 12px;
                        flex-shrink: 0;
                        &.span-progress-error {
                            color: #d22b40;
                            font-weight: 500;
                            @at-root html.dark
                                    div.upload-file-component
                                    div.file-list
                                    div.file-item
                                    div.column.progress-column
                                    div.progress-box
                                    span.span-progress-error {
                                color: #ff677d;
                            }
                        }
                        &.span-progress-done {
                            color: green;
                            font-weight: 500;

                            @at-root html.dark
                                    div.upload-file-component
                                    div.file-list
                                    div.file-item
                                    div.column.progress-column
                                    div.progress-box
                                    span.span-progress-done {
                                color: #4caf50;
                            }
                        }
                    }
                }
            }
        }
        div.column {
            min-width: 23px;
            padding: 5px 0;
            display: flex;
            align-items: center;
            text-align: start;
            text-wrap: nowrap;
            white-space: nowrap;
            overflow-wrap: break-word;
            &.one {
                padding-inline-start: 15px;
                video {
                    display: block;
                    width: 50px;
                    height: 50px;
                }
            }
            &.two {
                width: 100px;
                @media (max-width: 767.98px) {
                    width: 70px;
                }
                span {
                    display: block;
                    width: 100px;
                    @media (max-width: 767.98px) {
                        width: 70px;
                    }
                }
            }
            &.three {
                width: 72px;
                span {
                    width: 72px;
                }
            }
            &.four {
                width: 42px;
                span {
                    width: 42px;
                }
            }
            &.five {
                padding-inline-end: 15px;
                flex: 1;
                span {
                }
            }
            &.six {
                width: 45px;
                span {
                    width: 45px;
                }
            }
        }
    }
}
.default-uplaod-no-items {
    width: 100%;
    min-height: 250px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

form.video-modal-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .form-middle.video-modal-form {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 15px;
    }
    .checkbox-form-video-group {
        display: flex;
        gap: 50px;
    }
}
h2.vdeo-title-form {
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
        color: var(--main_color);
    }
}
.hint-position-field {
    padding-top: 6px;
    font-size: 13px;
    @media (max-width: 767.98px) {
        font-size: 9px;
    }
}
div.field-box-current-pdf {
    position: relative;
    p.pdf-select-text.current-name {
        span.ellipsis {
        }
    }

    .trash-icon.current-pdf-delete {
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
    }
}

.pdf-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.pdf-icon-download-dashbord {
    cursor: pointer;
}
.field-box.pbf-content-box {
    flex: 1;
    input#pdfName {
        display: block;
        width: 100%;
        padding-inline-end: 60px;
    }
}
.pdf-cancel {
    position: absolute !important;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    background-image: none !important;
    color: #fff !important;
    background-color: #d22b40;
    display: flex !important;
    padding: 5px !important;
    gap: 8px !important;
    &:hover {
        box-shadow: 0px 2px 9px 0px #d22b40 !important;
    }
    .trash-icon {
        color: #fff;
    }
}
.pdf-file {
    width: 100%;
    max-height: 125px;
    overflow: hidden;
}
.pdf-select-text {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    font-size: 18px;
    width: 100%;
    height: 33px;
    color: hsl(0, 0%, 50%);
    display: flex;
    align-items: center;
    &.current-name {
        color: var(--main_paragraph_color, #001653);
        span {
            max-width: 334px;
            @media (max-width: 767.98px) {
                max-width: 231px;
            }
        }
    }
}
