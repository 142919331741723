tr.table-body-row.courses-table-row {
    td {
        .td-content {
            display: flex;
            align-items: center;
            gap: 4px;
            white-space: nowrap;
            font-size: 14px;
            padding: 0;
            margin: auto;
            .td-content-left {
                display: flex;
                flex-direction: column;
                align-items: start;
                text-align: start;
                gap: 1px;
            }
            .row-image-hand {
                border-radius: 5px;
                width: 40px;
                height: 40px;
                overflow: hidden;
                flex-shrink: 0;
                &.img {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &.cell-row-1 {
            .td-content {
                padding: 0;
                width: 17px;
            }
        }
        &.cell-row-2 {
            .td-content {
                width: 64px;
            }
        }
        &.cell-row-3 {
            text-align: start;
            .td-content {
                width: 134px;
                color: var(--main_color);
                font-weight: 500;
                span.ellipsis {
                    width: 90px;
                }
            }
            div.td-content,
            a.td-content {
                margin: 0 !important;
                div.row-image-hand {
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        &.cell-row-4 {
            .td-content {
                width: 94px;
            }
        }
        &.cell-row-5 {
            .td-content {
                width: 100px;
                text-align: center;
                margin: auto;
            }
        }
        &.cell-row-6,
        &.cell-row-7,
        &.cell-row-8 {
            .td-content {
                flex-direction: column;
            }
        }
        &.cell-row-6 {
            .td-content {
                width: 47px;
            }
        }
        &.cell-row-7 {
            .td-content {
                width: 50px;
            }
        }
        &.cell-row-8 {
            .td-content {
                width: 40px;
            }
        }
        &.cell-row-9 {
            .td-content {
                width: 108px;
            }
        }
        &.cell-row-10 {
            .td-content {
                width: 109px;
            }
        }
    }
}
.td-content-stars {
    display: flex;
    align-items: center;
    gap: 1px;
    div.rating-number {
        color: var(--main_paragraph_color);
        font-size: 13px;
        line-height: 1;
    }
}
.fav-icon-box {
    display: flex;
    align-items: center;
}
