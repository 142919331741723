main.auth-page {
    @at-root html.dark main.auth-page {
        background-image: url("./../../../../../public//images/hero_stars_bg1.png");
        background-size: 100% 100%;
    }
}
section.auth {
    div.container {
        padding-top: 60px;
        padding-bottom: 60px;
        @media (max-width: 767.98px) {
            margin-top: 60px;
        }
        div.form-box {
            margin-top: 60px;
            background-color: var(--main_background_color);
            box-shadow: var(--box_shadow_two_form);
            max-width: 500px;
            margin: 50px auto;
            border-radius: 14px;
            padding: 15px;
            div.active-go-email {
                margin-top: 15px;
                text-align: center;
                p {
                    font-size: 15px;
                    font-weight: 500;
                    span {
                        font-size: 18px;
                        font-weight: 700;
                    }
                }
                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 12px;
                }
            }
            div.image-hand {
                display: flex;
                justify-content: center;
                align-items: center;
                div.logo-image-hand {
                    width: 65px;
                    height: 50px;
                    @media (max-width: 767.98px) {
                        width: 45px;
                        height: 35px;
                    }
                    img.logoLight {
                        display: block;
                        width: 100%;
                        height: 100%;
                        @at-root html.dark
                                section.auth
                                div.container
                                div.form-box
                                div.image-hand
                                div.logo-image-hand
                                img.logoLight {
                            display: none;
                        }
                    }
                    img.logoDark {
                        display: none;
                        width: 100%;
                        height: 100%;
                        @at-root html.dark
                                section.auth
                                div.container
                                div.form-box
                                div.image-hand
                                div.logo-image-hand
                                img.logoDark {
                            display: block;
                        }
                    }
                }
            }
            div.form-header {
                border-bottom: 1px solid #ccc;
                display: flex;
                flex-direction: column;
                padding-bottom: 7px;
                position: relative;
                div.form-header-top {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                }
                div.form-header-left {
                    h2.form-header-title {
                        font-weight: 600;
                        font-size: 29px;
                        color: var(--main_color);
                        white-space: nowrap;
                        line-height: 1;
                        @media (max-width: 767.98px) {
                            font-size: 17px;
                        }
                        &.forget {
                            @media (max-width: 767.98px) {
                                font-size: 15px;
                            }
                        }
                        &.code-reset {
                            font-size: 26px;
                            @media (max-width: 767.98px) {
                                font-size: 15px;
                            }
                        }
                        &.reset-change-pass {
                            font-size: 23px;
                            @media (max-width: 767.98px) {
                                font-size: 14px;
                            }
                        }
                    }
                    p.form-header-description {
                        font-size: 16px;
                        font-weight: 500;
                        @media (max-width: 767.98px) {
                            font-size: 14px;
                        }
                        @at-root section#reset-password-section.auth
                                div.container
                                div.form-box
                                div.form-header
                                p.form-header-description {
                            font-size: 15px;
                            @media (max-width: 767.98px) {
                                font-size: 11px;
                            }
                        }
                    }
                }
                div.form-header-right {
                    display: flex;
                    align-items: center;
                    span.form-header-logo {
                        font-weight: 600;
                        font-size: 22px;
                        white-space: nowrap;
                        @media (max-width: 767.98px) {
                            font-size: 16px;
                        }
                        span {
                            word-spacing: -5px;
                        }
                    }
                }
            }
            .err-server-msg:not(:empty) {
                margin-top: 8px;
                margin-bottom: 0;
                color: red;
                font-size: 14px;
                + form {
                    margin-top: 5px;
                }
            }
            form {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 22px;
                div.form-top {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    div.first-name-box {
                        width: 100%;
                    }
                    div.last-name-box {
                        width: 100%;
                    }
                }

                div.form-middle {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 22px;
                    div.field-wrapper {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                    div.email-box {
                        width: 100%;
                        position: relative;
                        div.email-field-wrapper {
                            .icon-email {
                                position: absolute;
                                top: 50%;
                                right: 5px;
                                transform: translateY(-50%);
                            }
                        }
                    }
                    div.password-box {
                        position: relative;
                        .forget-pass-link {
                            position: absolute;
                            bottom: -22px;
                            left: 0;
                            font-size: 14px;
                            @at-root html.dark .forget-pass-link {
                                color: #ccc;
                            }
                        }
                        div.password-field-wrapper {
                            #password,
                            #newPassword {
                                padding-top: 5px;
                                padding-bottom: 5px;
                            }
                        }
                    }
                    div.confirm-password-box {
                        position: relative;
                        div.confirm-field-wrapper {
                            #confirmPassword {
                                padding-top: 5px;
                                padding-bottom: 5px;
                            }
                        }
                    }
                }
                button.submit {
                    width: 100%;
                    padding: 10px 18px;
                    font-size: 18px;
                    margin: 9px auto 0;
                }
            }
            div.line-break {
                background-color: #ccc;
                height: 2px;
                position: relative;
                margin: 23px 0;
                span {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background-color: var(--main_background_color);
                    padding: 0 10px;
                    font-size: 18px;
                    font-weight: 700;
                }
            }
            div.o-auth-box {
                display: flex;
                justify-content: space-between;
                gap: 20px;
                button.oauth-button {
                    display: flex;
                    justify-content: center;
                    gap: 7px;
                    align-items: center;
                    width: 100%;
                    text-align: center;
                    .oauth-icon {
                        color: var(--main_color);
                        font-size: 20px;
                    }
                    span {
                        font-size: 13px;
                        @media (max-width: 767.98px) {
                            font-size: 11px;
                        }
                    }
                }
            }
            .toggle-login-pages {
                text-align: center;
                margin-top: 15px;
                font-size: 14px;
                p {
                    a.toggle-login-pages-link {
                        color: var(--main_color);
                    }
                }
            }
        }
    }
}
