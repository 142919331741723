@use "./../../../../../helpers/mixin" as M;

section#carts-courses-section {
    div.carts-courses-items-box {
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        row-gap: 20px;
        div.courses-card {
            width: 285px;
        }
        .larg-courses-card {
            @media (max-width: 767.98px) {
                width: fit-content;
                margin: auto;
            }
            &:nth-child(n + 4) {
                @media (max-width: 767.98px) {
                    display: block;
                }
            }
        }
    }
}
