section#course-ratings {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 40px;
    .opinions-title {
        margin: none;
        @media (max-width: 767.98px) {
            margin: auto;
        }
    }
    div.opinions-card.opinions-card-add-rating {
        margin-top: 23px;
        height: auto;
        padding: 10px 15px;
        div.opinions-card-top-add-rating {
            display: flex;
            align-items: center;
            gap: 7px;
            span.rating-number-text {
                font-size: 18px;
                font-weight: 500;
            }
            .opinions-card-top-right {
            }
        }

        p.opinions-card-bottom {
        }

        div.err-server-msg {
            p {
            }
        }
        form {
            div.form-middle {
                div.field-box {
                    div.field-wrapper {
                        #ratingText {
                            resize: none;
                            min-height: 35px;
                            max-height: 100px;
                            margin-bottom: 23px;
                            border: none;
                            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 1px
                                inset;
                        }
                    }
                }
            }
        }
    }
    div.opinions-box {
        margin-top: 40px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;
        max-height: 505px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 15px;
        span.no-review-hint {
            font-size: 16px;
            font-weight: 500;
            @media (max-width: 991.98px){
            	text-align: center;
            }
        }
    }
}

.edit-review-logged-user {
    min-width: 500px;
    div.opinions-card.opinions-card-add-rating {
        height: auto;
        padding: 0;
        div.opinions-card-top-add-rating {
            display: flex;
            align-items: center;
            gap: 7px;
            span.rating-number-text {
                font-size: 18px;
                font-weight: 500;
            }
            .opinions-card-top-right {
            }
        }

        p.opinions-card-bottom {
        }

        div.err-server-msg {
            p {
            }
        }
        form {
            div.form-middle {
                div.field-box {
                    div.field-wrapper {
                        #ratingText {
                            resize: none;
                            height: 70px;
                            margin-bottom: 23px;
                            border: none;
                            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px 1px
                                inset;
                        }
                    }
                }
            }
        }
    }
    .btn-review-box {
        display: flex;
        justify-content: space-between;
        .btn-edit-review {
            min-width: 87px;
        }
    }
}
