@forward "./units/EditField";

div.user-profile-sections, section#user-profile-sections {
    @media (max-width: 767.98px) {
        margin-top: 79px;
    }
    .user-profile-sections-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
    div.profile-box {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;
        @media (max-width: 991.98px){
            flex-direction: column;
            margin-bottom: 30px;
        }
        div.profile-box-right {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 50%;
            max-width: 50%;
            @media (max-width: 991.98px){
                width: 100%;
                max-width: 100%;
            }
        }
        div.profile-box-left {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 50%;
            @media (max-width: 991.98px){
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}
