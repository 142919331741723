div.hint-box-component {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #f3dc91;
    border-radius: 6px;
    padding: 5px 8px;
    width: fit-content;
    color: #212b43;
    .hint-icon {
    }
    p.under-upload-course-msg {
 
    }
    &.add-hint-margin-bottom {
        margin-bottom: 20px;
    }
}
