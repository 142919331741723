div.search {
    display: flex;
    position: relative;
    align-items: center;
    margin-left: 15px;
    @media (max-width: 767.98px){
        margin-left: 0px;
    }
    .icon-search {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--main_color);
    }
    form {
        input#search.search-input {
            border: 1px solid #ccc;
            padding: 2px 30px 2px 5px;
            height: 26px;
            width: 217px;
            border-radius: 5px;
            font-size: 18px;
            display: flex;
            align-items: center;
            @media (max-width: 767.98px) {
                padding: 3px 30px 3px 5px;
            }
        }
    }
}
div.courses-list-top-left {
    div.search {
        form {
            input#search.search-input {
                @media (max-width: 767.98px) {
                    width: 264px;
                }
            }
        }
    }
}
.form-search {
    .form-middle.form-middle-search {
        .field-box {
            position: relative;
            width: fit-content;
            @media (max-width: 767.98px) {
                position: absolute;
                bottom: -27px;
                right: 0;
                z-index: 800;
                width: 100%;
                display: block;
            }
        }
    }
    input#searchValue.search-input {
        border: 1px solid #ccc;
        padding: 2px 30px 2px 61px;
        max-height: 28px;
        width: 217px;
        border-radius: 5px;
        font-size: 18px;
        display: flex;
        align-items: center;
        @media (max-width: 767.98px) {
            padding: 3px 30px 3px 0;
            width: 100%;
        }
    }
    .solid-common-btn.submit.submit-btn-search {
        position: absolute;
        top: 50%;
        left: 3px;
        transform: translateY(-50%);
        height: 22px;
    }
}
