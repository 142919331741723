@forward "./blocks/VideosListTable";
@forward "./blocks/VideosListRow";

.profile-box-left.chapter-box-left {
    margin-top: 50px;
    @media (max-width: 767.98px){
        margin-top: 20px;
    }
}
.videos-list-box {
    margin-top: 25px;
    margin-bottom: 25px;
}