@use "./../../../../../helpers/variables" as V;

section#hero-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    height: calc(100dvh);
    @at-root html.dark section#hero-section {
        background-image: url("./../../../../../../../public/images/hero_stars_bg1.png");
        background-attachment: fixed;
    }
    div.container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        @media (max-width: 767.98px) {
            justify-content: flex-start;
        }
        div.hero-top {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @media (max-width: 767.98px) {
                margin-top: 102px;
            }
            h1 {
                font-size: 60px;
                max-width: 600px;
                font-weight: 800;
                background-clip: text;
                user-select: none !important;
                @media (max-width: 767.98px) {
                    font-size: 41px;
                }
                span {
                    word-spacing: -17px;
                    @media (max-width: 767.98px){
                        word-spacing: -9px;
                    }
                }
            }
            span.doctor {
                font-size: 34px;
                font-weight: 800;
                @media (max-width: 767.98px) {
                    font-size: 29px;
                }
            }
            p {
                margin-top: 15px;
                max-width: 600px;
                max-width: 800px;
                color: var(--hero_paragraph_color);
                font-size: 25px;
                @media (max-width: 767.98px) {
                    margin-top: 20px;
                    font-size: 20px;
                }
            }
        }
        div.hero-bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            gap: 20px;
            @media (max-width: 767.98px) {
                flex-direction: column;
            }
            a.courses-btn {
                font-size: 20px;
            }
            a.join-hero-btn {
                padding: 7px 20px;
                font-size: 20px;
            }
        }
    }
    img.hero-background-image {
        position: absolute;
        top: -1 * V.$header-height;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: contain;
        @media (max-width: 767.98px) {
            object-fit: cover;
        }
    }
    .header-scroll-btn {
        transform: translateY(-500%);
    }
}
