div.opinions-card {
    background-color: var(--cards_bg_color);
    border-radius: 8px;
    box-shadow: var(--box_shadow_two);
    padding: 10px;
    min-height: 150px;
    overflow: hidden;
    display: grid;
    align-items: flex-start;
    height: fit-content;
    &.cursor-grab {
        cursor: grab;
    }
    &.active {
        height: auto;
    }
    div.opinions-card-top {
        display: flex;
        justify-content: space-between;
        div.opinions-card-top-left {
            display: flex;
            gap: 7px;
            div.hand-image-opinion {
                border-radius: 50%;
                width: 50px;
                height: 50px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            div.header-opinion-card {
                p.opinions-card-student-name {
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 1.2;
                }
                span.opinions-card-student-description {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
        div.opinions-card-top-right {
            span {
                line-height: 1;
            }
        }
    }
    p.opinions-card-bottom {
        font-weight: 500;
        font-size: 16px;
        margin-top: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.6;
        height: calc(1.6 * 2);
        transition: 0.3s;
        span {
            display: block;
        }
        &.active {
            display: block;
            max-height: auto;
            margin-bottom: 10px;
        }
    }
    button.btn-more-opinion {
        width: fit-content;
        background-color: transparent;
        color: var(--main_color);
        justify-self: flex-end;
        padding-left: 3px;
    }
    div.opinions-card-action-box {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 5px;
        align-self: flex-end;
        justify-self: flex-end;
        margin-top: 8px;
        .opinions-card-edit-icon {
            cursor: pointer;
        }
        .opinions-card-trash-icon {
            cursor: pointer;
            &:hover {
                color: #d22b40;
            }
        }
    }
}
