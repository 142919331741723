.add-order-modal.form-top {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 15px;
}
h2.add-order-modal.form-header-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}
.order-modal-course-price {
    display: flex;
    align-items: center;
    gap: 5px;
    .price-after-discount {
        display: flex;
        align-items: center;
        font-weight: 600;
    }
    div.original-price {
        font-size: 20px;
        display: flex;
        align-items: center;
        font-weight: 600;
        span {
            width: fit-content;
        }
    }
    div.price-after-discount:not(:empty) + div.original-price span {
        position: relative;
        font-size: 16px;
        &::before {
            content: "";
            position: absolute;
            height: 2px;
            width: 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: #777;
            border-radius: 1px;
        }
        font-weight: 400;
        line-height: 1;
    }
}
.add-order-modal {
    .user-course-item {
        margin-top: 5px;
        border-radius: 8px;
        padding: 5px 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px -1px 1px 1px inset;
        min-width: 351px;
        @media (max-width: 767.98px){
            min-width: 278px;
        }
        @at-root html.dark .add-order-modal .user-course-item {
            background-color: var(--main_background_color);
        }
        &.add-margin-top {
            margin-top: 17px;
        }
    }
}
