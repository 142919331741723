.ReactModal__Overlay,
.ReactModal__Overlay--after-open {
    background-color: transparent !important;
    z-index: 3000;
}
.ql-toolbar {
    @media (max-width: 767.98px) {
        display: block !important;
    }
}

.ReactModal__Content {
    max-width: 650px;
    height: fit-content !important;
    background-color: var(--cards_bg_color) !important;
    box-shadow: var(--box_shadow_two);
    border-radius: 8px !important;
    @media (max-width: 767.98px) {
        max-width: 400px;
    }
    @media (min-width: 767.98px) and (max-width: 991.98px) {
        min-width: 400px;
    }
}
