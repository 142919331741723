section#about-landing {
    div.container {
        h1.elianto {
            text-align: center;
            span {
                word-spacing: -5px;
            }
        }
        div.about-landing-box {
            display: flex;
            justify-content: space-between;
            gap: 15px;
            margin-top: 20px;
            @media (max-width: 991.98px) {
                flex-direction: column;
            }
            div.about-landing-box-start {
                width: 50%;
                flex-shrink: 0;
                @media (max-width: 991.98px) {
                    width: 100%;
                }
                .video-react-has-started .video-react-control-bar {
                    background-color: #2dd4bec7;
                }
            }
            div.about-landing-box-end {
                ul.about-landing-main-ul {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-weight: 500;
                    font-size: 15px;
                    li.about-landing-main-li {
                        background-color: var(--cards_bg_color);
                        border-radius: 8px;
                        box-shadow: var(--box_shadow_two);
                        padding: 10px;
                        height: auto;
                        overflow: hidden;
                    }
                }
                ul.about-landing-nested-ul {
                    margin-top: 5px;
                    display: flex;
                    flex-direction: column;
                    gap: 2px;
                    margin-right: 5px;
                    li.about-landing-nested-li {
                        display: flex;
                        align-items: start;
                        gap: 5px;
                        font-size: 14px;
                        &::before {
                            content: "";
                            display: block;
                            height: 4px;
                            width: 4px;
                            border-radius: 50%;
                            border: 1px solid #999;
                            margin-top: 10px;
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }
    }
}
span.li-about-highlight-item {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: fit-content;
    margin: auto;
    padding: 3px;
    margin-inline: 3px;
    margin-block: 3px;
    @media (max-width: 767.98px) {
        display: block;
        width: auto;
    }
}
