@forward "./units/LectureSideBarUint";

main#lecture-item-page { 
  section.courses-content {
    div.courses-content-top {
      margin-bottom: 25px;
      .course-content-section-title {
        margin: auto;
        font-size: 30px;
        @media (max-width: 991.98px){
          font-size: 31px;
        }
      } 
    }
  }
}
