div.summary-page-card-box {
    background-color: var(--cards_bg_color);
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 30px;
    div.summary-box-top {
        h2.title-summary-box {
            font-size: 20px;
            font-weight: 800;
        }
        p.description-summary-box {
            margin-top: 5px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1;
        }
    }
    div.summary-box-bottom {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 15px;
        margin-top: 17px;
        div.summary-box-bottom-item {
            display: flex;
            align-items: center;
            gap: 10px;
            div.summary-box-bottom-item-right {
                border-radius: 5px;
                height: 100%;
                padding: 0 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.green {
                    color: green;
                    background-color: rgba(0, 128, 0, 0.288);
                    @at-root html.dark
                            div.summary-page-card-box
                            div.summary-box-bottom
                            div.summary-box-bottom-item
                            div.summary-box-bottom-item-right
                            .green {
                        color: rgb(1, 223, 1);
                        background-color: rgba(1, 223, 1, 0.288);
                    }
                }
                &.red {
                    color: #d22b40;
                    background-color: hsla(352, 66%, 50%, 0.288);
                    @at-root html.dark
                            div.summary-page-card-box
                            div.summary-box-bottom
                            div.summary-box-bottom-item
                            div.summary-box-bottom-item-right
                            .red {
                        color: #f53b53;
                        background-color: hsla(352, 90%, 60%, 0.288);
                    }
                }
                &.yellow {
                    color: #e4b007;
                    background-color: hsla(46, 80%, 52%, 0.288);
                    @at-root html.dark
                            div.summary-page-card-box
                            div.summary-box-bottom
                            div.summary-box-bottom-item
                            div.summary-box-bottom-item-right
                            .yellow {
                        color: #f0be1c;
                        background-color: hsla(46, 80%, 52%, 0.288);
                    }
                }
                &.orange {
                    color: #f08a04;
                    background-color: hsla(34, 97%, 48%, 0.288);
                    @at-root html.dark
                            div.summary-page-card-box
                            div.summary-box-bottom
                            div.summary-box-bottom-item
                            div.summary-box-bottom-item-right.orange {
                        color: #f08a04;
                        background-color: hsla(34, 97%, 48%, 0.288);
                    }
                }
                &.pink {
                    color: #a30589;
                    background-color: hsla(310, 94%, 33%, 0.288);
                    @at-root html.dark
                            div.summary-page-card-box
                            div.summary-box-bottom
                            div.summary-box-bottom-item
                            div.summary-box-bottom-item-right.pink {
                        color: #e706c2;
                        background-color: hsla(310, 95%, 46%, 0.288);
                    }
                }
                &.cyan {
                    color: #05a39b;
                    background-color: hsla(177, 94%, 46%, 0.288);
                    @at-root html.dark
                            div.summary-page-card-box
                            div.summary-box-bottom
                            div.summary-box-bottom-item
                            div.summary-box-bottom-item-right.cyan {
                        color: #81f5ef;
                        background-color: hsla(177, 86%, 58%, 0.288);
                    }
                }
            }
            div.summary-box-bottom-item-left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                div.summary-box-bottom-item-left-top {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: 600;
                }
                div.summary-box-bottom-item-left-bottom {
                    color: var(--main_color);
                    font-weight: 800;
                    font-size: 20px;
                }
            }
        }
    }
}
