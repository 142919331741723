div.content-unit {
    background-color: var(--cards_bg_color);
    border-radius: 8px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    div.content-unit-top {
        padding: 4px 20px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        word-break: break-all;
        cursor: pointer;
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        h3 {
            @media (max-width: 991.98px) {
                font-size: 16px;
            }
        }
        .icon-arrow {
            transition: transform 0.3s;
            &.active {
                transform: rotate(180deg);
            }
        }
    }
    div.content-unit-bottom {
        display: flex;
        flex-direction: column;
        gap: 5px;
        overflow: hidden;
        transition: height 0.3s, margin 0.3s;
        margin-bottom: 5px;
        a.title-item-video,
        div.title-item-video {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 5px 30px 5px 15px;
            flex: 1;
            border-radius: 8px;
            @media (max-width: 767.98px) {
                align-items: flex-start;
            }
            .play-icon {
                @media (max-width: 767.98px) {
                    margin-top: 3px;
                }
            }
            .item-title-box {
                display: flex;
                justify-content: space-between;
                width: 100%;
                p {
                    max-width: 484px;
                    @media (max-width: 767.98px) {
                        font-size: 12px;
                        max-width: 108px;
                    }
                }
            }
            &:first-child {
                padding-top: 9px;
            }
            .play-icon {
                color: var(--main_color);
                flex-shrink: 0;
            }
        }
    }
}
.course-content-box-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.align {
        align-items: flex-start;
        background-color: var(--main_background_color);
        margin: 0 5px;
        border-radius: 8px;
        a.watch-now {
            margin-top: 5px;
        }
    }
    .pdf-for-user {
        padding-left: 15px;
    }
    &.column {
        flex-direction: column;
        .title-item-video {
            width: 100%;
        }
    }
    .course-content-box-item-inner-box {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 30px 0 0;
        .title-item-video {
            padding: 5px 0 5px 0 !important;
            .item-title-box {
                p {
                    max-width: 532px !important;
                    @media (min-width: 991.98px) and (max-width: 1120px) {
                        max-width: 379px !important;
                    }
                    @media (max-width: 991.98px) {
                        max-width: 180px !important;
                    }
                }
            }
        }
        .pdf-for-user {
            font-size: 14px;
            margin-bottom: 5px;
            .pdf-name {
                max-width: 532px !important;
                @media (max-width: 1120px) {
                    max-width: 375px !important;
                }
                @media (max-width: 991.98px) {
                    max-width: 180px !important;
                }
            }
        }
        &.column {
            flex-direction: column;
            .title-item-video {
                width: 100%;
            }
        }
    }
}
a.watch-now {
    font-size: 14px;
    color: var(--main_color);
    flex-shrink: 0;
    padding-left: 15px;
    @media (max-width: 767.98px) {
        font-size: 12px;
    }
}
