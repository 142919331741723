tr.table-body-row.courses-table-row {
    td.users-list.cell-row-3.cell-row-course {
        div.td-content {
            div.td-content-left {
                span.ellipsis {
                    max-width: 228px;
                    width: auto;
                    display: block;
                }
            }
        }
    }
    td.users-list.cell-row-4.cell-row-course {
        div.td-content {
            span.ellipsis {
                margin: auto;
            }
        }
    }
    td.users-list.cell-row-5.cell-row-course {
        div.td-content {
            span.ellipsis {
                margin: auto;
            }
        }
    }
    td.users-list.cell-row-6.cell-row-course {
        div.td-content {
            width: 76px;
            span.ellipsis {
                margin: auto;
                font-weight: 500;
                &.student {
                    color: #2dd4bf;
                    background-color: #2dd4be0e;
                }
                &.viewer {
                    color: #857da4;
                    background-color: #857da40e;
                    @at-root html.dark
                            tr.table-body-row.courses-table-row
                            td.users-list.cell-row-6.cell-row-course
                            div.td-content
                            span.ellipsis.viewer {
                        color: #dadaf8;
                    }
                }
                &.admin {
                    color: #30374b;
                    background-color: #30374b13;
                    @at-root html.dark
                            tr.table-body-row.courses-table-row
                            td.users-list.cell-row-6.cell-row-course
                            div.td-content
                            span.ellipsis.admin {
                        color: #a4afcf;
                    }
                }
                &.owner {
                    color: #d22b40;
                    background-color: #d22b4115;
                    @at-root html.dark
                            tr.table-body-row.courses-table-row
                            td.users-list.cell-row-6.cell-row-course
                            div.td-content
                            span.ellipsis.owner {
                        color: #eb596d;
                    }
                }
            }
        }
    }
}
