@forward "./user/user";
@forward "./admin/admin";

div.dashbord-layout {
    .form-middle.form-middle-search {
        .field-box {
            position: relative;
            width: fit-content;
            @media (max-width: 767.98px) {
                position: static;
                bottom: auto;
                right: auto;
                z-index: auto;
                width: 100%;
                display: block;
            }
        }
    }
    @media (max-width: 767.98px) {
    }
    div.container {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        position: relative;
        .icon-expand.icon-open-sidebar {
            display: none;
            @media (max-width: 991.98px) {
                display: flex;
            }
            position: fixed;
            top: 31px;
            right: 10px;
            z-index: 1200;
            transform: translate(50%, -50%);
            justify-content: center;
            align-items: center;
            width: 58px;
            height: 58px;
            padding: 13px;
            border-radius: 50%;
            transition: 0.3s;
            color: var(--main_color);
            cursor: pointer;
            &.active {
                transform: translate(50%, -50%) rotate(180deg);
            }
        }
        div.dashbord-right {
            width: 200px;
            height: fit-content;
            min-height: 714px;
            margin-top: 20px;
            margin-bottom: 30px;
            padding: 10px;
            border-radius: 8px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            position: relative;
            background-color: var(--cards_bg_color);
            display: flex;
            flex-shrink: 0;
            flex-direction: column;
            gap: 15px;
            @media (max-width: 991.98px) {
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(156%);
                z-index: 1000;
                width: 100%;
                height: 100%;
                background-color: var(--sidebar_mobile_bg_color);
                transition: transform 0.3s;
                &.active {
                    transform: translateX(0%);
                }
            }
            @media (max-width: 767.98px) {
                margin-top: 95px;
            }
            @media (min-width: 767.98px) and (max-width: 991.98px) {
                margin-top: 0;
            }
            div.dashbord-link {
                display: flex;
                align-items: flex-start;
                gap: 7px;
                cursor: pointer;
                &.ative {
                    color: var(--main_color);
                }
                a {
                    line-height: 1.3;
                    font-size: 17px;
                    font-weight: 500;
                }
            }
        }
        div.main-content-layout {
            flex: 1 1;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}
.dashbord-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 100%;
}
.dashbord-edit-header-box {
    display: flex;
    align-items: center;
    gap: 3px;
    .edit-header-box-icon {
        color: var(--main_color);
    }
}
.course-dashbord-price {
    display: flex;
    flex-direction: column;
    gap: 15px;
    div.edit-field {
        width: 100%;
    }
}
.checkbox-box {
    display: flex;
    align-items: center;
    gap: 7px;
    input {
        width: fit-content;
    }
    input[type="checkbox"] {
        width: 18px;
        height: 18px;
    }
    label {
        font-size: 18px;
        font-weight: 500;
    }
}
.publish-box-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
        height: 35px;
        width: 35px;
        background-color: #000;
        color: #fff;
        border: none;
        &:hover {
            background-color: transparent;
        }
        @at-root html.dark .publish-box-btn svg {
            background-color: transparent;
            border: 1px solid #fff;
        }
    }
}
button.publish-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: transparent;
    padding: 15px;
    border: 1px solid var(--main_color);
    padding: 5px 15px;
    border-radius: 5px;
    font-weight: 600;
    color: var(--main_color);
    transition: 0.3s;
    height: 35px;
    &:hover {
        box-shadow: 0px 1px 9px 0px rgba(45, 212, 191, 0.4705882353);
    }
    .publish-icon {
        color: var(--main_color);
    }
    span.publish-span {
        font-weight: 600;
    }
}
.price-discount-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}
.current-value-categories-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    p {
        span {
            font-weight: 500;
        }
    }
}
.dashbord-edit-title-box {
    font-size: 18px;
    font-weight: 800;
}
