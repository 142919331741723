tr.orders-list.table-body-row.courses-table-row {
    td.orders-list.cell-row-3.cell-row-course {
        div.orders-list.td-content {
            cursor: auto;
            div.orders-list.td-content-left {
                span.orders-list.ellipsis.span-cell {
                    width: 130px;
                    @media (max-width: 767.98px) {
                        width: 90px;
                    }
                }
                .orders-list.td-content-user-id {
                    display: flex;
                    gap: 5px;
                }
            }
        }
    }
    td.orders-list.cell-row-4.cell-row-course {
        div.orders-list.td-content {
            width: 76px;
            justify-content: center;
        }
    }
    td.orders-list.cell-row-5.cell-row-course {
        div.orders-list.td-content {
            justify-content: center;
            width: 70px;
        }
    }
    td.orders-list.cell-row-8.cell-row-course {
        div.orders-list.td-content {
            width: 76px;
        }
    }
    td.orders-list.cell-row-11.cell-row-course {
        div.orders-list.td-content {
            width: 70px;
        }
    }
    td.orders-list.cell-row-10.cell-row-course {
        div.orders-list.td-content {
            justify-content: center;
            width: 77px;
            .edit-btn-row-view {
                padding: 6px;
            }
        }
    }
}
