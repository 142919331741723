tr.coupons-list.table-body-row.courses-table-row {
    td.coupons-list.cell-row-4.cell-row-course {
        div.coupons-list.td-content {
            justify-content: center;
            span.coupons-list.ellipsis.span-cell {
                text-align: center;
            }
        }
    }
}
