section#sort-my-course-section {
    display: flex;
    gap: 15px;
    @media (max-width: 767.98px) {
        justify-content: center;
    }
    div.sort-by-box {
        display: flex;
        gap: 15px;
        @media (max-width: 767.98px) {
            gap: 7px;
        }
    }
    .courses-page-link {
        display: block;
        padding-top: 3px;
        padding-bottom: 3px;
        &:hover,
        &.active {
            box-shadow: 0px 1px 9px 0px #2dd4bf78;
        }
    }
}
