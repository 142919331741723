.box-style-upload-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.dropzone-styles-upload-image {
    border: 2px dashed #ccc;
    border-radius: 4px;
    width: 100%;
    height: 250px;
    text-align: center;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    &.not-active {
        cursor: auto;
    }
    &.profile {
        height: 163px;
        width: 163px;
        border-radius: 50%;
        overflow: hidden;
    }
    .text-upload-box-upload-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;
        .default-text-uplaod-image {
            font-size: 14px;
            padding: 0 10px;
        }
    }
    .image-container-styles-upload-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .image-styles-upload-image {
            width: 100%;
            height: 100%;
            border-radius: 4px;
        }
    }
}
div.edit-field
    div.edit-bottom
    form
    div.form-middle.description-course-form-middle {
    gap: 5px;
}
div.image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    &.profile {
        border: 2px solid #ccc;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 163px;
        width: 163px;
        border-radius: 50%;
        margin: auto;
        overflow: hidden;
        div.hand-Image {
            width: 100%;
            height: 100%;
            img.image-course-current-value {
                width: 100%;
                height: 100%;
            }
        }
    }
    div.hand-Image {
        width: 100%;
        height: 250px;
        img.image-course-current-value {
            width: 100%;
            height: 100%;
        }
    }
}
