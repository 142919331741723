@use "./../../helpers/variables" as V;

header {
    height: V.$header-height;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1111;
    @media (max-width: 767.98px) {
        position: fixed;
        top: 0;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
        background: var(--main_background_color);
    }
    div.container {
        display: flex;
        align-items: center;
        gap: 15px;
        height: 100%;
        @media (max-width: 767.98px) {
            gap: 8px;
        }
        .logo-text {
            display: flex;
            align-items: center;
            gap: 10px;
            div.logo-image-hand {
                display: flex;
                align-items: center;
                gap: 10px;
                width: 54px;
                height: 42px;
                cursor: pointer;
                img.logoLightPure {
                    width: 100%;
                    height: 100%;
                    display: block;
                    @at-root html.dark
                            header
                            div.container
                            .logo-text
                            div.logo-image-hand
                            img.logoLightPure {
                        display: none;
                    }
                }
                img.logoDarkPure {
                    width: 100%;
                    height: 100%;
                    display: none;
                    @at-root html.dark
                            header
                            div.container
                            .logo-text
                            div.logo-image-hand
                            img.logoDarkPure {
                        display: block;
                    }
                }
            }
            span {
                word-spacing: -5px;
                @media (max-width: 767.98px) {
                    display: none;
                }
            }
            div.search {
                form {
                    input#search.search-input {
                        border: 1px solid #ccc;
                        @media (max-width: 767.98px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
        ul.link-list {
            display: flex;
            height: 100%;
            flex: 1;
            align-items: center;
            justify-content: flex-end;
            gap: 15px;
            font-weight: 500;
            color: var(--main_title_color);
            @media (max-width: 991.98px) {
                position: absolute;
                background-image: var(--menu_gradient);
                background-repeat: no-repeat;
                background-size: cover;
                top: calc(V.$header-height);
                left: 0;
                z-index: 1100;
                $radius-ul: 35%;
                border-end-start-radius: $radius-ul;
                border-end-end-radius: $radius-ul;
                width: 100%;
                height: 0;
                overflow: hidden;
                transition: height 0.3s;
                flex-direction: column;
                box-shadow: var(--box_shadow_three);
            }
            &.active {
                @media (max-width: 991.98px) {
                    flex-direction: column;
                    justify-content: flex-start;
                    height: calc(25px * (3 + 2));
                }
                &.student {
                    @media (max-width: 991.98px) {
                        height: calc(25px * (4 + 2));
                    }
                }
            }
            li {
                font-weight: 500;
                position: relative;
                color: var(--main_paragraph_color);
                &.active {
                    font-weight: 700;
                    color: var(--main_title_color);
                }
                &::before {
                    content: "";
                    position: absolute;
                    bottom: -3px;
                    right: 0;
                    width: 0;
                    height: 3px;
                    background-color: var(--main_color);
                    transition: width 0.6s;
                    border-radius: 50%;
                }
                &:hover::before {
                    width: 40%;
                }
            }
        }
        a.link-shop {
            display: flex;
            align-items: center;
            .icon-shop {
                transform: scaleX(-1);
            }
        }
        .menu-icon-hand {
            display: none;
            cursor: pointer;
            @media (max-width: 991.98px) {
                display: flex;
                justify-content: flex-end;
            }
        }
        div.hand-theme-icon {
            display: flex;
            justify-content: flex-end;
            @media (max-width: 991.98px) {
                flex: 1;
            }
            .theme-icon {
                border: 1px solid var(--main_title_color);
                color: var(--main_title_color);
                border-radius: 50%;
                padding: 3px;
                @media (max-width: 767.98px) {
                    width: 27px;
                    height: 27px;
                }
            }
        }
        div.btn-group-header {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 13px;
            a.login {
                color: var(--main_color);
                font-weight: 600;
                font-size: 16px;
                font-size: 15px;
                @media (max-width: 991.98px) {
                    font-size: 13px;
                }
            }
            a.header-join {
                @media (max-width: 991.98px) {
                    padding: 3px 8px;
                    font-size: 14px;
                }
            }
        }
        div.user-menu {
            display: flex;
            align-items: center;
            position: relative;
            img.user-avatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                cursor: pointer;
                @media (max-width: 767.98px) {
                    width: 30px;
                    height: 30px;
                }
            }
            ul {
                display: flex;
                opacity: 0;
                flex-direction: column;
                align-items: center;
                gap: 7px;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 1100;
                transform: translateY(100%);
                width: 158px;
                background-image: var(--menu_gradient);
                border-radius: 8px;
                text-align: center;
                margin: auto;
                padding: 10px;
                transition: opacity 0.3s;
                pointer-events: none;
                &.active {
                    opacity: 1;
                    pointer-events: auto;
                }
                li {
                    p.user-logged-name {
                        font-weight: 700;
                        color: var(--main_color);
                    }
                }
            }
        }
    }
}
