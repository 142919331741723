div.user-course-items-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 222px;
    overflow-y: auto;
}
div.user-course-item {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    border-bottom: 1px solid #ddd;
    padding: 2px 0;
    @at-root html.dark div.user-course-items-box div.user-course-item {
        border-bottom: 1px solid #dddddd3a;
        &:last-child {
            border-bottom: none;
        }
    }
    &:last-child {
        border-bottom: none;
    }
    div.item-right {
        display: flex;
        gap: 5px;
        div.image-hand {
            border-radius: 5px;
            width: 51px;
            height: 51px;
            overflow: hidden;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
            }
        }
        div.item-content {
            display: flex;
            flex-direction: column;
            p.title {
                max-width: 300px;
                font-weight: 500;
                @media (max-width: 767.98px) {
                    max-width: 184px;
                }
            }
            div.text-wrap {
                display: flex;
                gap: 5px;
                span.course-id {
                    max-width: 300px;
                    padding-bottom: 3px;
                    @media (max-width: 767.98px) {
                        max-width: 184px;
                    }
                }
            }
        }
    }
}
