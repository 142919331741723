* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 1.6;
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: "VipHalaBold";
    src: url("./../../../public/fonts/VIP Hala Bold VIP Hala Bold.otf");
}

@font-face {
    font-family: "EliantoRegular";
    src: url("./../../../public/fonts/Elianto-Regular.ttf");
}

html {
    -webkit-scroll-behavior: smooth;
    scroll-behavior: smooth;
}

body {
    position: relative;
    z-index: 1;
    caret-color: var(--main_color);
}

video::-internal-media-controls-download-button {
    display: none;
}

video::-webkit-media-controls-enclosure {
    overflow: hidden;
}

video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
}

img {
    font-size: 12px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
}
main {
    min-height: 100vh;
}

.container {
    padding: 0 15px;
    margin: auto;
}

input[type="checkbox"],
input[type="radio"] {
    accent-color: var(--main_color);
}

.field-wrapper {
    position: relative;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--main_color);
    border-radius: 4px;
    box-shadow: 0 0 2px 1px #ccc inset;
}
/* small */
@media (max-width: 767.9px) {
    .container {
        width: 357px;
    }
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
/* medium */
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
/* large */
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}