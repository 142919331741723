$hieght-item: 70px;
div.cart-order-Item,
.courses-in-cart-header {
    display: flex;
    gap: 20px;
    @media (max-width: 767.98px) {
        gap: 7px;
    }
}
div.cart-order-Item {
    div.cart-order-Item-part1 {
        display: flex;
        gap: 10px;
        div.image-hand {
            width: 100px;
            height: 100px;
            border-radius: 8px;
            overflow: hidden;
            flex-shrink: 0;
            @media (max-width: 767.98px) {
                width: $hieght-item;
                height: $hieght-item;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
        p.cart-order-Item-title {
            @media (max-width: 767.98px) {
                font-size: 14px;
            }
        }
        div.mobile-title-price-box {
            max-height: $hieght-item;
            font-weight: 600;
        }
    }
    div.cart-order-Item-part2 {
        div.price-after-discount {
            font-weight: 600;
            font-size: 20px;
        }
        div.original-price {
            font-size: 20px;
            font-weight: 600;
            span {
                width: fit-content;
            }
        }
        div.price-after-discount:not(:empty) + div.original-price span {
            position: relative;
            font-size: 16px;
            &::before {
                content: "";
                position: absolute;
                height: 2px;
                width: 100%;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: #777;
                border-radius: 1px;
            }
            font-weight: 400;
            line-height: 1;
        }
    }
}
div.cart-order-Item-part1 {
    width: 300px;
    @media (max-width: 767.98px) {
        width: 255px;
    }
}
div.cart-order-Item-part2 {
    text-align: center;
    width: 150px;
    &.active-in-mobile {
        display: none;
        @media (max-width: 767.98px) {
            display: flex;
            align-items: center;
            gap: 5px;
            .price-after-discount {
                display: flex;
                align-items: center;
            }
            .original-price {
                display: flex;
                align-items: center;
                font-weight: 600;
            }
        }
    }
    &.not-active-in-mobile {
        display: block;
        @media (max-width: 767.98px) {
            display: none;
        }
    }
}
div.cart-order-Item-part3 {
    text-align: center;
    width: 70px;
    @media (max-width: 767.98px) {
        width: 37px;
    }
}
